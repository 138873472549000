const initialState = {
  name: "",
  state: "new", // new/connecting/connected/disconnected/closed,
  roomRecordingState: "stop", // stop/recording/paused,
  locked: false,
  webinar: false,
  inLobby: false,
  signInRequired: false,
  accessCode: "", // access code to the room if locked and joinByAccessCode == true
  joinByAccessCode: true, // if true: accessCode is a possibility to open the room
  activeSpeakerId: null,
  showSettings: false,
  fullScreenConsumer: null, // ConsumerID
  windowConsumer: null, // ConsumerID
  toolbarsVisible: true,
  mode: "democratic",
  selectedPeerId: null,
  spotlights: [],
  settingsOpen: false,
  invitationOpen: false,
  clarificationTextOpen:
    sessionStorage.getItem("clarificationTextOpen") === "true",
  lockDialogOpen: false,
  leaveDialogOpen: false,
  joined: false,
  muteAllInProgress: false,
  stopAllVideoInProgress: false,
  closeMeetingInProgress: false,
  userRoles: { NORMAL: "normal" }, // Default role
  permissionsFromRoles: {
    CHANGE_ROOM_LOCK: [],
    PROMOTE_PEER: [],
    SEND_CHAT: [],
    SHARE_SCREEN: [],
    SHARE_FILE: [],
    MODERATE_ROOM: [],
    MODERATE_WEBINAR: [],
    WEBINAR_SPEAKER: [],
    RECORD: [],
  },
  startTime: 0,
  recordingNotificationStatus: false,
  changeModerator: { isDialogOpen: false, peerId: null },
  controlBarVisibility: true,
  whiteBoardEnabled: true,
  meetingInviteEnabled: true,
  fileTransferEnabled: true,
  meetingRecorderEnabled: true,
  meetingSuspended: false,
  hasInterpreterSupporting: false,
  isInterpreterLanguageApproved: false,
  interpreters: [],
  interpreterActiveLanguage: sessionStorage.getItem(
    "interpreterActiveLanguage"
  ),
  participantLog: [],
  password: null,
  hasPassword: false,
  validatePassword: false,
  roomKey: "",
  maxNumberOfPeer: {
    license: 100,
    current: 100,
  },
  maxNumberOfVideo: {
    maxValue: 50,
    current: 50,
  },
};

const room = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ROOM_NAME": {
      const { name } = action.payload;

      return { ...state, name };
    }

    case "SET_ROOM_STATE": {
      const roomState = action.payload.state;

      if (roomState === "connected") return { ...state, state: roomState };
      else return { ...state, state: roomState, activeSpeakerId: null };
    }

    case "SET_ROOM_RECORDING_STATE": {
      const roomRecordingState = action.payload.state;
      return { ...state, roomRecordingState };
    }

    case "SET_ROOM_LOCKED": {
      return { ...state, locked: true };
    }

    case "SET_ROOM_UNLOCKED": {
      return { ...state, locked: false };
    }

    case "SET_IN_LOBBY": {
      const { inLobby } = action.payload;

      return { ...state, inLobby };
    }

    case "SET_SIGN_IN_REQUIRED": {
      const { signInRequired } = action.payload;

      return { ...state, signInRequired };
    }

    case "SET_ACCESS_CODE": {
      const { accessCode } = action.payload;

      return { ...state, accessCode };
    }

    case "SET_JOIN_BY_ACCESS_CODE": {
      const { joinByAccessCode } = action.payload;

      return { ...state, joinByAccessCode };
    }

    case "SET_LOCK_DIALOG_OPEN": {
      const { lockDialogOpen } = action.payload;

      return { ...state, lockDialogOpen };
    }

    case "SET_LEAVE_DIALOG_OPEN": {
      const { leaveDialogOpen } = action.payload;

      return { ...state, leaveDialogOpen };
    }

    case "SET_SETTINGS_OPEN": {
      const { settingsOpen } = action.payload;

      return { ...state, settingsOpen };
    }

    case "SET_INVITATION_OPEN": {
      const { invitationOpen } = action.payload;

      return { ...state, invitationOpen };
    }

    case "SET_CLARIFICATION_TEXT_OPEN": {
      const { clarificationTextOpen } = action.payload;

      return { ...state, clarificationTextOpen };
    }

    case "SET_ROOM_ACTIVE_SPEAKER": {
      const { peerId } = action.payload;

      return { ...state, activeSpeakerId: peerId };
    }

    case "TOGGLE_JOINED": {
      const joined = true;

      return { ...state, joined };
    }

    case "TOGGLE_FULLSCREEN_CONSUMER": {
      const { consumerId } = action.payload;
      const currentConsumer = state.fullScreenConsumer;

      return {
        ...state,
        fullScreenConsumer: currentConsumer ? null : consumerId,
      };
    }

    case "TOGGLE_WINDOW_CONSUMER": {
      const { consumerId } = action.payload;
      const currentConsumer = state.windowConsumer;

      if (currentConsumer === consumerId)
        return { ...state, windowConsumer: null };
      else return { ...state, windowConsumer: consumerId };
    }

    case "SET_TOOLBARS_VISIBLE": {
      const { toolbarsVisible } = action.payload;

      return { ...state, toolbarsVisible };
    }

    case "SET_DISPLAY_MODE":
      return { ...state, mode: action.payload.mode };

    case "SET_SELECTED_PEER": {
      const { selectedPeerId } = action.payload;

      return {
        ...state,

        selectedPeerId:
          state.selectedPeerId === selectedPeerId ? null : selectedPeerId,
      };
    }

    case "SET_SPOTLIGHTS": {
      const { spotlights } = action.payload;

      return { ...state, spotlights };
    }

    case "MUTE_ALL_IN_PROGRESS":
      return { ...state, muteAllInProgress: action.payload.flag };

    case "STOP_ALL_VIDEO_IN_PROGRESS":
      return { ...state, stopAllVideoInProgress: action.payload.flag };

    case "CLOSE_MEETING_IN_PROGRESS":
      return { ...state, closeMeetingInProgress: action.payload.flag };

    case "SET_USER_ROLES": {
      const { userRoles } = action.payload;

      return { ...state, userRoles };
    }

    case "SET_PERMISSIONS_FROM_ROLES": {
      const { permissionsFromRoles } = action.payload;

      return { ...state, permissionsFromRoles };
    }

    case "SET_ROOM_WEBINAR": {
      const { flag } = action.payload;
      return { ...state, webinar: flag };
    }

    case "SET_RECORDING_NOTIFICATION_STATUS": {
      const { recordingNotificationStatus } = action.payload;
      return { ...state, recordingNotificationStatus };
    }

    case "SET_START_TIME": {
      const { startTime } = action.payload;
      return { ...state, startTime };
    }

    case "SET_CHANGE_MODERATOR": {
      const { changeModerator } = action.payload;

      return { ...state, changeModerator };
    }

    case "SET_CONTROL_BAR_VISIBILITY": {
      const { controlBarVisibility } = action.payload;
      return { ...state, controlBarVisibility };
    }

    case "SET_WHITEBOARD_ENABLED": {
      const whiteBoardEnabled = !state.whiteBoardEnabled;
      return { ...state, whiteBoardEnabled };
    }
    case "SET_MEETING_INVITE_ENABLED": {
      const meetingInviteEnabled = !state.meetingInviteEnabled;
      return { ...state, meetingInviteEnabled };
    }
    case "SET_MEETING_RECORDER_ENABLED": {
      const meetingRecorderEnabled = !state.meetingRecorderEnabled;
      return { ...state, meetingRecorderEnabled };
    }
    case "SET_FILE_TRANSFER_ENABLED": {
      const fileTransferEnabled = !state.fileTransferEnabled;
      return { ...state, fileTransferEnabled };
    }

    case "SET_MEETING_SUSPENDED":
      const { meetingSuspended } = action.payload;
      return { ...state, meetingSuspended };

    case "SET_INTERPRETER_SUPPORTING":
      return {
        ...state,
        hasInterpreterSupporting: action.payload.hasInterpreterSupporting,
      };
    case "SET_INTERPRETER_LANGUAGE_APPROVED":
      return {
        ...state,
        isInterpreterLanguageApproved:
          action.payload.isInterpreterLanguageApproved,
      };
    case "SET_INTERPRETERS":
      return {
        ...state,
        interpreters: action.payload.interpreters,
      };
    case "SET_INTERPRETER_ACTIVE_LANGUAGE":
      return {
        ...state,
        interpreterActiveLanguage: action.payload.interpreterActiveLanguage,
      };
    case "SET_PARTICIPANT_LOG":
      return {
        ...state,
        participantLog: action.payload.participantLog,
      };
    case "SET_PASSWORD":
      return {
        ...state,
        password: action.payload.password,
      };
    case "SET_HAS_PASSWORD":
      return {
        ...state,
        hasPassword: action.payload.hasPassword,
      };
    case "SET_VALIDATE_PASSWORD":
      return {
        ...state,
        validatePassword: action.payload.validatePassword,
      };
    case "SET_ROOM_KEY":
      return {
        ...state,
        roomKey: action.payload.roomKey,
      };
    case "SET_MAX_NUMBER_OF_PEER":
      return {
        ...state,
        maxNumberOfPeer: action.payload.maxNumberOfPeer,
      };
    case "SET_MAX_NUMBER_OF_VIDEO":
      return {
        ...state,
        maxNumberOfVideo: {
          maxValue: state.maxNumberOfVideo.maxValue,
          current: action.payload.maxNumberOfVideo,
        },
      };
    default:
      return state;
  }
};

export default room;
