import React from "react";
import { connect } from "react-redux";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import * as themeActions from "./actions/themeActions";

const DynamicThemeProvider = (props) => {
  const { addThemes, addDefaultTheme, defaultTheme } = props;

  let theme;
  if (defaultTheme) {
    theme = defaultTheme;
  } else if (sessionStorage.getItem("theme")) {
    addThemes(window.themes);
    theme = window.themes.find(
      (theme) => theme.id === sessionStorage.getItem("theme")
    );

    if (!theme) {
      theme = window.themes[0];
    }
    addDefaultTheme(theme);
  } else {
    addThemes(window.themes);
    theme = window.themes[0];
    if (window.config && window.config.defaultTheme) {
      theme = window.themes.find(
        (theme) => theme.id === window.config.defaultTheme
      );
    }
    addDefaultTheme(theme);
  }

  sessionStorage.setItem("theme", theme.id);
  let createdTheme = createTheme(theme);
  return (
    <MuiThemeProvider theme={createdTheme}>
      {props.children}
    </MuiThemeProvider>
  );
};

function mapStateToProps(state) {
  return {
    defaultTheme: state.theme.defaultTheme,
  };
}

const mapDispatchToProps = (dispatch) => ({
  addThemes: (themes) => {
    dispatch(themeActions.addThemes(themes));
  },
  addDefaultTheme: (defaultTheme) => {
    dispatch(themeActions.addDefaultTheme(defaultTheme));
  },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatesEqual: (next, prev) => {
    return prev.theme.defaultTheme === next.theme.defaultTheme;
  },
})(DynamicThemeProvider);
