import * as resizeActions from "../actions/resizeActions";
const initialState = {
    democraticWidth: 0,
    screenShareWidth: 0
};

const resizeReducer = (state = initialState, action) => {

    switch (action.type) {
        case "HORIZONTAL_SPLIT_RESIZE":
            console.log("VE", action.payload);
            return { ...state, democraticWidth: action.payload.state.to, screenShareWidth: action.payload.state.to };
        default:
            return state;
    }
};

export default resizeReducer;