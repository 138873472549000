export const setSelectedAudioDevice = (deviceId) => ({
  type: "CHANGE_AUDIO_DEVICE",
  payload: { deviceId },
});

export const setSelectedAudioOutputDevice = (deviceId) => ({
  type: "CHANGE_AUDIO_OUTPUT_DEVICE",
  payload: { deviceId },
});

export const setSelectedWebcamDevice = (deviceId) => ({
  type: "CHANGE_WEBCAM",
  payload: { deviceId },
});

export const setVideoResolution = (resolution) => ({
  type: "SET_VIDEO_RESOLUTION",
  payload: { resolution },
});

export const setDisplayName = (displayName) => ({
  type: "SET_DISPLAY_NAME",
  payload: { displayName },
});

export const toggleAdvancedMode = () => ({
  type: "TOGGLE_ADVANCED_MODE",
});

export const togglePermanentTopBar = (permanentTopBar) => ({
  type: "TOGGLE_PERMANENT_TOPBAR",
  payload: { permanentTopBar },
});

export const setLastN = (lastN) => ({
  type: "SET_LAST_N",
  payload: { lastN },
});

export const setTotalN = (totalN) => ({
  type: "SET_TOTAL_N",
  payload: { totalN },
});

export const changeChatSound = (chatSound) => ({
  type: "SET_CHAT_SOUND",
  payload: { chatSound },
});

export const hasVirtualClassroom = (virtualClassroom) => ({
  type: "SET_VIRTUAL_CLASSROOM",
  payload: { virtualClassroom },
});

export const changeWhiteboardSound = (whiteboardSound) => ({
  type: "SET_WHITEBOARD_SOUND",
  payload: { whiteboardSound },
});

export const setScreenShareOptimizedForVideo = (
  optimizeScreenShareForVideo
) => ({
  type: "SET_SCREEN_SHARE_OPTIMIZED_FOR_VIDEO",
  payload: { optimizeScreenShareForVideo },
});

export const setMirrorOwnImage = () => ({
  type: "SET_MIRROR_OWN_IMAGE",
});

export const setPictureInPicture = () => ({
  type: "SET_PICTURE_IN_PICTURE",
});

export const setWebcamAspectRatio = (aspectRatio) => ({
  type: "SET_WEBCAM_ASPECT_RATIO",
  payload: { aspectRatio },
});
export const setParticipantListVisibility = (participantListVisibility) => ({
  type: "SET_PARTICIPANT_LIST_VISIBILITY",
  payload: { participantListVisibility },
});

export const setExplicitConsentApproval = (explicitConsentApproval) => ({
  type: "SET_EXPLICIT_CONSENT_APPROVAL",
  payload: { explicitConsentApproval },
});

export const changeNewPeerSound = (newPeerSound) => ({
  type: "SET_NEW_PEER_SOUND",
  payload: { newPeerSound },
});
