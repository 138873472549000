import Logger from "./Logger";
import hark from "hark";
import {getSignalingUrl} from "./urlFactory";
import * as requestActions from "./actions/requestActions";
import * as meActions from "./actions/meActions";
import * as roomActions from "./actions/roomActions";
import * as peerActions from "./actions/peerActions";
import * as peerVolumeActions from "./actions/peerVolumeActions";
import * as settingsActions from "./actions/settingsActions";
import * as chatActions from "./actions/chatActions";
import * as whiteBoardActions from "./actions/whiteBoardActions";
import * as fileActions from "./actions/fileActions";
import * as lobbyPeerActions from "./actions/lobbyPeerActions";
import * as consumerActions from "./actions/consumerActions";
import * as producerActions from "./actions/producerActions";
import * as notificationActions from "./actions/notificationActions";
import * as userActions from "./actions/userActions";
import JoinSteps from "./model/JoinSteps";

import RecordRTC from "recordrtc";
import * as dataConsumerActions from "./actions/dataConsumerActions";
import * as dataProducerActions from "./actions/dataProducerActions";
import {createNewMessage} from "./reducers/helper";
import chat from "./reducers/chat";
import * as Logout from "./Logout";
import moment from "moment";

const CryptoJS = require("crypto-js");
const Papa = require("papaparse");

let saveAs;

let mediasoupClient;

let io;

let ScreenShare;

let Spotlights;

let requestTimeout,
  transportOptions,
  lastN,
  totalN,
  mobileLastN,
  mobileTotalN,
  defaultResolution,
  virtualClassroom,
  shareScreenAudio,
  useDataChannel;

if (process.env.NODE_ENV !== "test") {
  ({
    requestTimeout,
    transportOptions,
    lastN,
    totalN,
    mobileLastN,
    mobileTotalN,
    defaultResolution,
    virtualClassroom,
    shareScreenAudio,
    useDataChannel,
  } = window.config);
}

const logger = new Logger("RoomClient");

const ROOM_OPTIONS = {
  requestTimeout: requestTimeout,
  transportOptions: transportOptions,
};

let VIDEO_CONSTRAINS = {
  verylow: {
    width: { ideal: 160 },
    aspectRatio: 1.334,
  },
  low: {
    width: { ideal: 240 },
    aspectRatio: 1.334,
  },
  medium: {
    width: { ideal: 320 },
    aspectRatio: 1.334,
  },
  high: {
    width: { ideal: 480 },
    aspectRatio: 1.334,
  },
  veryhigh: {
    width: { ideal: 640 },
    aspectRatio: 1.334,
  },
  ultra: {
    width: { ideal: 1280 },
    aspectRatio: 1.334,
  },
};

const PC_PROPRIETARY_CONSTRAINTS = {
  optional: [{ googDscp: true }],
};

const VIDEO_SIMULCAST_ENCODINGS = [
  {
    rid: "r1",
    maxBitrate: 95000,
    scaleResolutionDownBy: 2,
    maxFramerate: 14,
  },
  { rid: "r0", maxBitrate: 650000, maxFramerate: 16 },
];

// Used for VP9 webcam video.
const VIDEO_KSVC_ENCODINGS = [{ scalabilityMode: "S3T3_KEY" }];

// Used for VP9 desktop sharing.
const VIDEO_SVC_ENCODINGS = [{ scalabilityMode: "S3T3", dtx: true }];

let store;

let intl;

let interpreterLanguageObserverId = null;

let roomLog;

export default class RoomClient {
  _recorder = undefined;
  blobs = [];
  recordStreams = [];
  isWebcamEnabledOnceFlag = false;

  constructor({
    peerId,
    accessCode,
    device,
    useSimulcast,
    useSharingSimulcast,
    produce,
    forceTcp,
    displayName,
    muted,
    userToken,
  } = {}) {
    if (!peerId) throw new Error("Missing peerId");
    else if (!device) throw new Error("Missing device");

    logger.debug(
      'constructor() [peerId: "%s", device: "%s", useSimulcast: "%s", produce: "%s", forceTcp: "%s", displayName ""]',
      peerId,
      device.flag,
      useSimulcast,
      produce,
      forceTcp,
      displayName
    );

    this._signalingUrl = null;

    // Closed flag.
    this._closed = false;

    // Whether we should produce.
    this._produce = produce;

    // Wheter we force TCP
    this._forceTcp = forceTcp;

    // Use mosaic stream
    //store.dispatch(roomActions.setMosaicStream('Mosaic stream input area'));

    // Use displayName
    if (displayName)
      store.dispatch(settingsActions.setDisplayName(displayName));

    // Whether simulcast should be used.
    this._useSimulcast = useSimulcast;

    if ("simulcast" in window.config)
      this._useSimulcast = window.config.simulcast;

    // Whether simulcast should be used for sharing
    this._useSharingSimulcast = useSharingSimulcast;

    if ("simulcastSharing" in window.config)
      this._useSharingSimulcast = window.config.simulcastSharing;

    this._muted = muted;

    this._userToken = userToken;

    // This device
    this._device = device;

    // My peer name.
    this._peerId = peerId;

    // Access code
    this._accessCode = accessCode;

    // Alert sound
    this._soundAlert = new Audio("/sounds/notify.mp3");

    // Socket.io peer connection
    this._signalingSocket = null;

    // The room ID
    this._roomId = null;

    // mediasoup-client Device instance.
    // @type {mediasoupClient.Device}
    this._mediasoupDevice = null;

    // Put the browser info into state
    store.dispatch(meActions.setBrowser(device));

    if (defaultResolution)
      store.dispatch(settingsActions.setVideoResolution(defaultResolution));

    if (virtualClassroom !== undefined)
      store.dispatch(settingsActions.hasVirtualClassroom(virtualClassroom));
    else store.dispatch(settingsActions.hasVirtualClassroom(false));

    // Max spotlights
    if (device.browser.getPlatformType() === "desktop") {
      this._maxSpotlights = totalN;
      store.dispatch(settingsActions.setLastN(lastN));
      if (!totalN) totalN = lastN;
      store.dispatch(settingsActions.setTotalN(totalN));
    } else {
      this._maxSpotlights = mobileTotalN;
      store.dispatch(settingsActions.setLastN(mobileLastN));
      if (!mobileTotalN) mobileTotalN = mobileLastN;
      store.dispatch(settingsActions.setTotalN(mobileTotalN));
    }

    // Manager of spotlight
    this._spotlights = null;

    // Transport for sending.
    this._sendTransport = null;

    // Transport for receiving.
    this._recvTransport = null;

    // Local mic mediasoup Producer.
    this._micProducer = null;

    // Local mic hark
    this._hark = null;

    // Local webcam mediasoup Producer.
    this._webcamProducer = null;

    // Local Chat mediasoup DataProducer.
    this._dataProducer = null;

    // Boolean Flag for DataProducer
    this._useDataChannel = useDataChannel;

    // Map of Shared Secret Keys Sent to Peers
    // @type {Map<String, String>}
    this._secretKeysSent = new Map();

    // Map of Shared Secret Keys Received from Peers
    // @type {Map<String, String>}
    this._secretKeysReceived = new Map();

    // Transport Connection State Indicator
    this._connectionState = null;
    // Map of webcam MediaDeviceInfos indexed by deviceId.
    // @type {Map<String, MediaDeviceInfos>}
    this._webcams = {};

    this._audioDevices = {};

    this._audioOutputDevices = {};

    // mediasoup Consumers.
    // @type {Map<String, mediasoupClient.Consumer>}
    this._consumers = new Map();

    // mediasoup DataConsumers.
    // @type {Map<String, mediasoupClient.DataConsumer>}
    this._dataConsumers = new Map();

    this._screenSharingForRecorder = null;

    this._screenSharing = null;

    this._screenSharingProducer = null;
    this._screenSharingAudioProducer = null;

    this._startKeyListener();

    this._startDevicesListener();

    store.dispatch(settingsActions.togglePermanentTopBar(true));
  }

  /**
   * @param  {Object} data
   * @param  {Object} data.store - The Redux store.
   * @param  {Object} data.intl - react-intl object
   */
  static init(data) {
    store = data.store;
    intl = data.intl;
  }

  getOptimizedVideoResolution() {
    const numberOfVideos =
      [...this._consumers.values()].filter((c) => c.kind === "video").length +
      1;

    if (numberOfVideos <= 3) {
      return "veryhigh";
    } else if (numberOfVideos <= 6) {
      return "medium";
    } else if (numberOfVideos <= 11) {
      return "low";
    }
    return "verylow";
  }

  getRoomId() {
    return this._roomId;
  }

  close() {
    if (this._closed) return;

    if (this._recording) this.stopRecording();

    this._closed = true;

    logger.debug("close()");

    this._signalingSocket.close();

    // Close mediasoup Transports.
    if (this._sendTransport) this._sendTransport.close();

    if (this._recvTransport) this._recvTransport.close();

    store.dispatch(roomActions.setRoomState("closed"));

    window.location = "/";

    Logout.endSession();
  }

  _startKeyListener() {
    // Add keypress event listner on document
    document.addEventListener("keypress", (event) => {
      const webinar = store.getState().room.webinar;
      if (webinar) return;
      const key = String.fromCharCode(event.which);

      const source = event.target;

      const exclude = ["input", "textarea"];

      if (exclude.indexOf(source.tagName.toLowerCase()) === -1) {
        logger.debug('keyPress() [key:"%s"]', key);

        switch (key) {
          case "a": {
            // Activate advanced mode
            store.dispatch(settingsActions.toggleAdvancedMode());
            store.dispatch(
              requestActions.notify({
                text: intl.formatMessage({
                  id: "room.toggleAdvancedMode",
                  defaultMessage: "Toggled advanced mode",
                }),
              })
            );
            break;
          }

          case "1": {
            // Set democratic view
            store.dispatch(roomActions.setDisplayMode("democratic"));
            store.dispatch(
              requestActions.notify({
                text: intl.formatMessage({
                  id: "room.setDemocraticView",
                  defaultMessage: "Changed layout to democratic view",
                }),
              })
            );
            break;
          }

          case "2": {
            // Set filmstrip view
            store.dispatch(roomActions.setDisplayMode("filmstrip"));
            store.dispatch(
              requestActions.notify({
                text: intl.formatMessage({
                  id: "room.setFilmStripView",
                  defaultMessage: "Changed layout to filmstrip view",
                }),
              })
            );
            break;
          }

          case "m": {
            if (
              store.getState().settings.explicitConsentApproval &&
              !store.getState().room.meetingSuspended
            ) {
              // Toggle microphone
              if (this._micProducer) {
                if (!this._micProducer.paused) {
                  this.muteMic();

                  store.dispatch(
                    requestActions.notify({
                      text: intl.formatMessage({
                        id: "devices.microPhoneMute",
                        defaultMessage: "Muted your microphone",
                      }),
                    })
                  );
                } else {
                  this.unmuteMic();

                  store.dispatch(
                    requestActions.notify({
                      text: intl.formatMessage({
                        id: "devices.microPhoneUnMute",
                        defaultMessage: "Unmuted your microphone",
                      }),
                    })
                  );
                }
              } else {
                this.enableMic();

                store.dispatch(
                  requestActions.notify({
                    text: intl.formatMessage({
                      id: "devices.microphoneEnable",
                      defaultMessage: "Enabled your microphone",
                    }),
                  })
                );
              }
            }
            break;
          }

          case "v": {
            if (
              store.getState().settings.explicitConsentApproval &&
              !store.getState().room.meetingSuspended
            ) {
              // Toggle video
              if (this._webcamProducer) this.disableWebcam();
              else this.enableWebcam();
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    });
  }

  _startDevicesListener() {
    if (navigator && navigator.mediaDevices)
      navigator.mediaDevices.addEventListener("devicechange", async () => {
        logger.debug(
          "_startDevicesListener() | navigator.mediaDevices.ondevicechange"
        );

        await this._updateAudioDevices();
        await this._updateWebcams();
        await this._updateAudioOutputDevices();

        store.dispatch(
          requestActions.notify({
            text: intl.formatMessage({
              id: "devices.devicesChanged",
              defaultMessage:
                "Your devices changed, configure your devices in the settings dialog",
            }),
          })
        );
      });
  }

  login() {
    const url = `https://localhost/login?peerId=${this._peerId}&roomId=${this._roomId}`;

    window.open(url, "loginWindow");
  }

  logout() {
    window.open("/auth/logout", "logoutWindow");
  }

  receiveLoginChildWindow(data) {
    logger.debug('receiveFromChildWindow() | [data:"%o"]', data);

    const { displayName, picture } = data;

    store.dispatch(settingsActions.setDisplayName(displayName));
    store.dispatch(meActions.setPicture(picture));

    store.dispatch(meActions.loggedIn(true));

    if (sessionStorage.getItem("meetingInvitationEnabled") === "true") {
      store.dispatch(
        userActions.setJoinStep(JoinSteps.ROOM_OR_MEETING_INVITATION)
      );
    } else {
      store.dispatch(userActions.setJoinStep(JoinSteps.PRODUCER_SELECTION));
    }

    store.dispatch(
      requestActions.notify({
        text: intl.formatMessage({
          id: "room.loggedIn",
          defaultMessage: "You are logged in",
        }),
      })
    );
  }

  receiveLogoutChildWindow() {
    logger.debug("receiveLogoutChildWindow()");

    store.dispatch(meActions.setPicture(null));

    store.dispatch(meActions.loggedIn(false));

    store.dispatch(
      requestActions.notify({
        text: intl.formatMessage({
          id: "room.loggedOut",
          defaultMessage: "You are logged out",
        }),
      })
    );
  }

  _soundNotification() {
    const alertPromise = this._soundAlert.play();

    if (alertPromise !== undefined) {
      alertPromise.then().catch((error) => {
        logger.error("_soundAlert.play() | failed: %o", error);
      });
    }
  }

  notify(text) {
    store.dispatch(requestActions.notify({ text: text }));
  }

  timeoutCallback(callback) {
    let called = false;

    const interval = setTimeout(() => {
      if (called) return;
      called = true;
      callback(new Error("Request timeout."));
    }, ROOM_OPTIONS.requestTimeout);

    return (...args) => {
      if (called) return;
      called = true;
      clearTimeout(interval);

      callback(...args);
    };
  }

  sendRequest(method, data) {
    //console.log('Send Request IN.');
    const sendRequestInTime = new Date().getMilliseconds();
    return new Promise((resolve, reject) => {
      if (!this._signalingSocket) {
        reject("No socket connection.");
      } else {
        this._signalingSocket.emit(
          "request",
          { method, data },
          this.timeoutCallback((err, response) => {
            if (err) {
              reject(err);
            } else {
              resolve(response);
            }
          })
        );
      }
      //console.log('Send Request OUT. Time elapsed: ' + (new Date().getMilliseconds() - sendRequestInTime) + 'ms.');
    });
  }

  async getTurnServerConfigs() {
    logger.debug("getTurnServerConfigs() ");

    try {
      return await this.sendRequest("turnServer:getTurnServerCredentials");
    } catch (error) {
      logger.error("getTurnServerConfigs() | failed: %o", error);
    }
  }

  async changeDisplayName(displayName) {
    logger.debug('changeDisplayName() [displayName:"%s"]', displayName);

    if (!displayName) displayName = "Katılımcı";

    store.dispatch(meActions.setDisplayNameInProgress(true));

    try {
      const {
        uniqueName,
      } = await this.sendRequest("uiAction:changeDisplayName", { displayName });

      store.dispatch(settingsActions.setDisplayName(uniqueName));

      store.dispatch(
        requestActions.notify({
          text: intl.formatMessage(
            {
              id: "room.changedDisplayName",
              defaultMessage: "Your display name changed to {displayName}",
            },
            {
              displayName,
            }
          ),
        })
      );
    } catch (error) {
      logger.error("changeDisplayName() | failed: %o", error);

      /*			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'room.changeDisplayNameError',
						defaultMessage : 'An error occurred while changing your display name'
					})
				}));*/
    }

    store.dispatch(meActions.setDisplayNameInProgress(false));
  }

  async changePicture(picture) {
    logger.debug('changePicture() [picture: "%s"]', picture);

    try {
      await this.sendRequest("changePicture", { picture });
    } catch (error) {
      logger.error("changePicture() | failed: %o", error);
    }
  }

  async changeMaxNumberOfPeer(number) {
    logger.debug('changeMaxNumberOfPeer() [number: "%s"]', number);

    try {
      await this.sendRequest("moderator:changeMaxNumberOfPeer", { number });
    } catch (error) {
      logger.error("changeMaxNumberOfPeer() | failed: %o", error);
    }
  }

  async changeMaxNumberOfVideo(number) {
    logger.debug('changeMaxNumberOfVideo() [number: "%s"]', number);
    try {
      await this.sendRequest("moderator:changeMaxNumberOfVideo", { number });

      store.dispatch(
        requestActions.notify({
          type: "info",
          text: intl.formatMessage({
            id: "room.maxNumberOfVideoChanged",
            defaultMessage: "Maximum number of video changed",
          }),
        })
      );
    } catch (error) {
      logger.error("changeMaxNumberOfVideo() | failed: %o", error);
    }
  }

  async sendWhiteBoardEvent(event) {
    logger.debug("sendWhiteBoardEvent()");

    try {
      await this.sendRequest("uiAction:whiteBoardEvent", { event });
    } catch (error) {
      logger.error("sendWhiteBoardEvent() | failed: %o", error);
    }
  }

  saveFile(file) {
    file.getBlob((err, blob) => {
      if (err) {
        return store.dispatch(
          requestActions.notify({
            type: "error",
            text: intl.formatMessage({
              id: "filesharing.saveFileError",
              defaultMessage: "Unable to save file",
            }),
          })
        );
      }

      saveAs(blob, file.name);
    });
  }

  handleDownload(magnetUri) {
    store.dispatch(fileActions.setFileActive(magnetUri));
  }

  async shareFiles(files) {
    store.dispatch(
      requestActions.notify({
        text: intl.formatMessage({
          id: "filesharing.startingFileShare",
          defaultMessage: "Attempting to share file",
        }),
      })
    );
  }

  // { file, name, picture }
  async _sendFile(magnetUri) {
    logger.debug("sendFile() [magnetUri: %o]", magnetUri);

    try {
      await this.sendRequest("sendFile", { magnetUri });
    } catch (error) {
      logger.error("sendFile() | failed: %o", error);

      store.dispatch(
        requestActions.notify({
          type: "error",
          text: intl.formatMessage({
            id: "filesharing.unableToShare",
            defaultMessage: "Unable to share file",
          }),
        })
      );
    }
  }

  async getServerHistory(joinAudio, joinVideo) {
    logger.debug("getServerHistory()");

    try {
      const {
        chatHistory,
        fileHistory,
        lastNHistory,
        locked,
        meetingSuspended,
        lobbyPeers,
        accessCode,
        webinar,
        whiteBoardData,
        recordingNotificationStatus,
        startTime,
        whiteBoardEnabled,
        meetingInviteEnabled,
        fileTransferEnabled,
        meetingRecorderEnabled,
      } = await this.sendRequest("uiAction:serverHistory");

      if (webinar) {
        store.dispatch(roomActions.setRoomWebinar(true));
        if (store.getState().room.mode !== "virtualClassroom") {
          store.dispatch(roomActions.setDisplayMode("filmstrip"));
        }
      }

      if (whiteBoardEnabled !== store.getState().room.whiteBoardEnabled) {
        store.dispatch(roomActions.setWhiteBoardEnabled());
      }
      if (whiteBoardData) {
        store.dispatch(whiteBoardActions.setData(whiteBoardData));
      }
      if (meetingInviteEnabled !== store.getState().room.meetingInviteEnabled) {
        store.dispatch(roomActions.setMeetingInviteEnabled());
      }
      if (fileTransferEnabled !== store.getState().room.fileTransferEnabled) {
        store.dispatch(roomActions.setFileTransferEnabled());
      }
      if (
        meetingRecorderEnabled !== store.getState().room.meetingRecorderEnabled
      ) {
        store.dispatch(roomActions.setMeetingRecordingEnabled());
      }

      chatHistory.length > 0 &&
        store.dispatch(chatActions.addChatHistory(chatHistory));

      fileHistory.length > 0 &&
        store.dispatch(fileActions.addFileHistory(fileHistory));

      if (lastNHistory.length > 0) {
        logger.debug("Got lastNHistory");

        // Remove our self from list
        const index = lastNHistory.indexOf(this._peerId);

        lastNHistory.splice(index, 1);

        this._spotlights.addSpeakerList(lastNHistory);
      }

      locked
        ? store.dispatch(roomActions.setRoomLocked())
        : store.dispatch(roomActions.setRoomUnLocked());

      store.dispatch(roomActions.setMeetingSuspended(meetingSuspended));

      if (!webinar) {
        // Don't produce if explicitely requested to not to do it.
        if (this._produce) {
          if (
            joinAudio &&
            this._mediasoupDevice.canProduce("audio") &&
            !store.getState().room.meetingSuspended
          ) {
            if (!this._muted) this.enableMic();
          }

          if (
            joinVideo &&
            this._mediasoupDevice.canProduce("video") &&
            !store.getState().room.meetingSuspended
          ) {
            this.enableWebcam();
          }

          if (this._useDataChannel) {
            this.enableDataProducer();
          } else {
            logger.warn("No Data Channel!");
          }
        }
      }

      lobbyPeers.length > 0 &&
        lobbyPeers.forEach((peer) => {
          store.dispatch(lobbyPeerActions.addLobbyPeer(peer.peerId));
          store.dispatch(
            lobbyPeerActions.setLobbyPeerDisplayName(
              peer.displayName,
              peer.peerId
            )
          );
          store.dispatch(lobbyPeerActions.setLobbyPeerPicture(peer.picture));
        });

      accessCode != null &&
        store.dispatch(roomActions.setAccessCode(accessCode));

      store.dispatch(
        roomActions.setRecordingNotificationStatus(recordingNotificationStatus)
      );
      if (startTime) {
        store.dispatch(roomActions.setStartTime(startTime));
      }
    } catch (error) {
      logger.error("getServerHistory() | failed: %o", error);
    }
  }

  async muteMic() {
    logger.debug("muteMic()");

    this._micProducer.pause();

    try {
      await this.sendRequest("peer:pauseProducer", {
        producerId: this._micProducer.id,
      });

      store.dispatch(producerActions.setProducerPaused(this._micProducer.id));
      store.dispatch(
        requestActions.notify({
          text: intl.formatMessage({
            id: "mic.closed",
            defaultMessage: "Your microphone has turned off",
          }),
        })
      );
    } catch (error) {
      logger.error("muteMic() | failed: %o", error);

      store.dispatch(
        requestActions.notify({
          type: "error",
          text: intl.formatMessage({
            id: "devices.microphoneMuteError",
            defaultMessage: "Unable to mute your microphone",
          }),
        })
      );
    }
  }

  async unmuteMic() {
    logger.debug("unmuteMic()");

    if (!this._micProducer) {
      await this.enableMic();
    } else {
      this._micProducer.resume();

      try {
        await this.sendRequest("peer:resumeProducer", {
          producerId: this._micProducer.id,
        });

        store.dispatch(
          producerActions.setProducerResumed(this._micProducer.id)
        );
        store.dispatch(
          requestActions.notify({
            text: intl.formatMessage({
              id: "mic.opened",
              defaultMessage: "Your microphone has turned on",
            }),
          })
        );
      } catch (error) {
        logger.error("unmuteMic() | failed: %o", error);

        store.dispatch(
          requestActions.notify({
            type: "error",
            text: intl.formatMessage({
              id: "devices.microphoneUnMuteError",
              defaultMessage: "Unable to unmute your microphone",
            }),
          })
        );
      }
    }
  }

  changeMaxSpotlights(maxSpotlights) {
    store.dispatch(settingsActions.setLastN(maxSpotlights));

    const screenPinnedPeer = Object.values(store.getState().peers)
      .filter((peer) => peer.screenPin)
      .map((peer) => peer.id);
    this._spotlights.pinnedPeers = screenPinnedPeer;
    this._spotlights.maxSpotlights = maxSpotlights;
  }

  // Updated consumers based on spotlights
  async updateSpotlights(spotlights) {
    logger.debug("updateSpotlights()");

    try {
      for (const consumer of this._consumers.values()) {
        if (consumer.kind === "video") {
          if (spotlights.indexOf(consumer.appData.peerId) > -1) {
            if (consumer._paused) await this._resumeConsumer(consumer);
          } else {
            await this._pauseConsumer(consumer);
          }
        }
      }
    } catch (error) {
      logger.error("updateSpotlights() failed: %o", error);
    }
  }

  async getAudioTrack() {
    await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });
  }

  async getVideoTrack() {
    await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: true,
    });
  }

  async changeAudioDevice(deviceId) {
    logger.debug("changeAudioDevice() [deviceId: %s]", deviceId);

    store.dispatch(meActions.setAudioInProgress(true));

    try {
      const device = this._audioDevices[deviceId];

      if (!device) throw new Error("no audio devices");

      logger.debug(
        "changeAudioDevice() | new selected webcam [device:%o]",
        device
      );

      if (this._micProducer && this._micProducer.track)
        this._micProducer.track.stop();

      logger.debug("changeAudioDevice() | calling getUserMedia()");

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: { exact: device.deviceId },
          echoCancellation: true,
          googTypingNoiseDetection: true,
          googEchoCancellation: true,
          googAutoGainControl: true,
          googNoiseSuppression: true,
          googHighpassFilter: true,
          googAudioMirroring: false,
          googNoiseSuppression2: true,
          googEchoCancellation2: true,
          googAutoGainControl2: true,
          googDucking: true,
          chromeRenderToAssociatedSink: true,
          autoGainControl: true,
          channelCount: 1,
          noiseSuppression: true,
          noiseSuppression2: true,
          echoCancellation2: true,
          latency: 1.0, // latency in seconds
          latency2: 1.0,
          googLatency: 1.0,
          googLatency2: 1.0,
          sampleRate: 48000,
          sampleSize: 16,
        },
      });

      const track = stream.getAudioTracks()[0];
      this.appendRecording(stream);

      if (this._micProducer) await this._micProducer.replaceTrack({ track });

      if (this._micProducer) this._micProducer.volume = 0;

      const harkStream = new MediaStream();

      harkStream.addTrack(track);

      if (!harkStream.getAudioTracks()[0])
        throw new Error("changeAudioDevice(): given stream has no audio track");

      if (this._hark != null) this._hark.stop();

      this._hark = hark(harkStream, { play: false });

      // eslint-disable-next-line no-unused-vars
      this._hark.on("volume_change", (dBs, threshold) => {
        // The exact formula to convert from dBs (-100..0) to linear (0..1) is:
        //   Math.pow(10, dBs / 20)
        // However it does not produce a visually useful output, so let exagerate
        // it a bit. Also, let convert it from 0..1 to 0..10 and avoid value 1 to
        // minimize component renderings.
        let volume = Math.round(Math.pow(10, dBs / 85) * 10);

        if (volume === 1) volume = 0;

        volume = Math.round(volume);

        if (
          this._roomId === null ||
          (this._micProducer && volume !== this._micProducer.volume)
        ) {
          store.dispatch(peerVolumeActions.setPeerVolume(this._peerId, volume));
        } else if (this._micProducer && volume !== this._micProducer.volume) {
          this._micProducer.volume = volume;
        }
      });
      if (this._micProducer && this._micProducer.id)
        store.dispatch(
          producerActions.setProducerTrack(this._micProducer.id, track)
        );

      store.dispatch(settingsActions.setSelectedAudioDevice(deviceId));

      await this._updateAudioDevices();
    } catch (error) {
      logger.error("changeAudioDevice() failed: %o", error);
    }

    store.dispatch(meActions.setAudioInProgress(false));
  }

  async changeAudioOutputDevice(deviceId) {
    logger.debug('changeAudioOutputDevice() [deviceId:"%s"]', deviceId);

    store.dispatch(meActions.setAudioOutputInProgress(true));

    try {
      const device = this._audioOutputDevices[deviceId];

      if (!device)
        throw new Error("Selected audio output device no longer available");

      store.dispatch(settingsActions.setSelectedAudioOutputDevice(deviceId));

      await this._updateAudioOutputDevices();
    } catch (error) {
      logger.error('changeAudioOutputDevice() [error:"%o"]', error);
    }

    store.dispatch(meActions.setAudioOutputInProgress(false));
  }

  async changeVideoResolution() {
    const resolution = this.getOptimizedVideoResolution();
    logger.debug("changeVideoResolution() [resolution: %s]", resolution);

    store.dispatch(meActions.setWebcamInProgress(true));

    try {
      const deviceId = await this._getWebcamDeviceId();

      const device = this._webcams[deviceId];

      if (!device) throw new Error("no webcam devices");

      this._webcamProducer.track.stop();

      logger.debug("changeVideoResolution() | calling getUserMedia()");
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: { exact: device.deviceId },
          ...VIDEO_CONSTRAINS[resolution],
        },
      });

      const track = stream.getVideoTracks()[0];

      await this._webcamProducer.replaceTrack({ track });

      store.dispatch(
        producerActions.setProducerTrack(this._webcamProducer.id, track)
      );

      store.dispatch(settingsActions.setSelectedWebcamDevice(deviceId));
      store.dispatch(settingsActions.setVideoResolution(resolution));

      await this._updateWebcams();
    } catch (error) {
      logger.error("changeVideoResolution() failed: %o", error);
    }

    store.dispatch(meActions.setWebcamInProgress(false));
  }

  async changeChatSound(chatSound) {
    try {
      await store.dispatch(settingsActions.changeChatSound(chatSound));
    } catch (error) {
      logger.error("changeChatSound() failed: %o", error);
    }
  }

  async changeNewPeerSound(newPeerSound) {
    try {
      await store.dispatch(settingsActions.changeNewPeerSound(newPeerSound));
    } catch (error) {
      logger.error("changeNewPeerSound() failed: %o", error);
    }
  }

  changeWhiteboardSound(whiteboardSound) {
    try {
      store.dispatch(settingsActions.changeWhiteboardSound(whiteboardSound));
    } catch (error) {
      logger.error("changeWhiteboardSound() failed: %o", error);
    }
  }

  setScreenShareOptimizedForVideo(optimizeScreenShareForVideo) {
    try {
      store.dispatch(
        settingsActions.setScreenShareOptimizedForVideo(
          optimizeScreenShareForVideo
        )
      );
    } catch (error) {
      logger.error("changeScreenShareConfigForVideo() failed: %o", error);
    }
  }

  async setMirrorOwnImage() {
    try {
      await store.dispatch(settingsActions.setMirrorOwnImage());
    } catch (error) {
      logger.error("setMirrorOwnImage() failed: %o", error);
    }
  }

  async setPictureInPicture() {
    try {
      await store.dispatch(settingsActions.setPictureInPicture());
    } catch (error) {
      logger.error("setPictureInPicture() failed: %o", error);
    }
  }


  async changeWebcam(deviceId) {
    if (!this._webcamProducer && !store.getState().room.meetingSuspended) {
      this.enableWebcam();
    }
    logger.debug("changeWebcam() [deviceId: %s]", deviceId);

    store.dispatch(meActions.setWebcamInProgress(true));

    try {
      const device = this._webcams[deviceId];
      if (!device) throw new Error("no webcam devices");

      logger.debug("changeWebcam() | new selected webcam [device:%o]", device);
      if (this._webcamProducer && this._webcamProducer.track)
        this._webcamProducer.track.stop();

      logger.debug("changeWebcam() | calling getUserMedia()");

      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: { exact: device.deviceId },
          ...VIDEO_CONSTRAINS[this.getOptimizedVideoResolution()],
        },
      });
      if (stream) {
        const track = stream.getVideoTracks()[0];
        if (track) {
          await this._webcamProducer.replaceTrack({ track });

          store.dispatch(
            producerActions.setProducerTrack(this._webcamProducer.id, track)
          );
        } else {
          logger.warn("getVideoTracks Error: First Video Track is null");
        }
      } else {
        logger.warn("getUserMedia Error: Stream is null!");
      }
      store.dispatch(settingsActions.setSelectedWebcamDevice(deviceId));

      await this._updateWebcams();
    } catch (error) {
      logger.error("changeWebcam() failed: %o", error);
    }

    store.dispatch(meActions.setWebcamInProgress(false));
  }

  setSelectedPeer(peerId) {
    // logger.debug('setSelectedPeer() [peerId:"%s"]', peerId);
    //
    // this._spotlights.setPeerSpotlight(peerId);
    //
    // store.dispatch(
    //     roomActions.setSelectedPeer(peerId));
  }

  async promoteLobbyPeer(peerId) {
    logger.debug('promoteLobbyPeer() [peerId:"%s"]', peerId);

    store.dispatch(
      lobbyPeerActions.setLobbyPeerPromotionInProgress(peerId, true)
    );

    try {
      await this.sendRequest("uiAction:promotePeer", { peerId });
    } catch (error) {
      logger.error("promoteLobbyPeer() failed: %o", error);
    }

    store.dispatch(
      lobbyPeerActions.setLobbyPeerPromotionInProgress(peerId, false)
    );
  }

  async changeModerator(peerId) {
    logger.debug("changeModerator: ", peerId);
    try {
      await this.sendRequest("moderator:changeModerator", {
        targetPeerId: peerId,
        currentPeerId: store.getState().me.id,
      });
    } catch (error) {
      logger.error("sendStopPeerMic() | failed: %o", error);
    }
  }

  async kickPeer(peerId) {
    logger.debug('kickPeer() [peerId:"%s"]', peerId);

    store.dispatch(peerActions.setPeerKickInProgress(peerId, true));

    try {
      await this.sendRequest("moderator:kickPeer", { peerId });
    } catch (error) {
      logger.error("kickPeer() failed: %o", error);
    }

    store.dispatch(peerActions.setPeerKickInProgress(peerId, false));
  }

  async changeInterpreterActiveLanguage(interpreterActiveLanguage) {
    logger.debug(
      'changeInterpreterActiveLanguage() [interpreterActiveLanguage:"%s"]',
      interpreterActiveLanguage
    );
    store.dispatch(
      roomActions.setInterpreterActiveLanguage(interpreterActiveLanguage)
    );
    try {
      await this.sendRequest("peer:interpreter:defaultLanguage", {
        interpreterActiveLanguage,
      });
    } catch (error) {
      logger.error("changeInterpreterActiveLanguage() failed: %o", error);
    }
  }

  async setNativeLanguage(nativeLanguage) {
    logger.debug('setNativeLanguage() [nativeLanguage:"%s"]', nativeLanguage);
    store.dispatch(roomActions.setInterpreterLanguageApproved(true));
    store.dispatch(meActions.setNativeLanguage(nativeLanguage));
    //this.interpreterMicSettings(true);
    try {
      await this.sendRequest("peer:setNativeLanguage", { nativeLanguage });
    } catch (error) {
      logger.error("setNativeLanguage() failed: %o", error);
    }
  }

  async setMeetingSuspended(meetingSuspended) {
    logger.debug(
      'setMeetingSuspended() [meetingSuspended:"%s"]',
      meetingSuspended
    );

    try {
      await store.dispatch(roomActions.setMeetingSuspended(meetingSuspended));
      await this.sendRequest("moderator:setMeetingSuspended", {
        meetingSuspended: meetingSuspended,
      });
    } catch (error) {
      logger.error("setMeetingSuspended() failed: %o", error);
    }
  }

  async setInterpreterSupporting(hasInterpreterSupporting) {
    logger.debug(
      'setInterpreterSupporting() [hasInterpreterSupporting:"%s"]',
      hasInterpreterSupporting
    );

    try {
      await this.sendRequest("moderator:setInterpreterSupporting", {
        hasInterpreterSupporting,
      });
    } catch (error) {
      logger.error("setInterpreterSupporting() failed: %o", error);
    }
  }

  async addInterpreterRole(peerId) {
    logger.debug('addInterpreterRole() [peerId:"%s"]', peerId);

    try {
      await this.sendAddRole(peerId, "webinar_speaker");
      await this.sendAddRole(peerId, "interpreter");
    } catch (error) {
      logger.error("addInterpreterRole() failed: %o", error);
    }
  }

  async removeInterpreterRole(peerId) {
    logger.debug('removeInterpreterRole() [peerId:"%s"]', peerId);

    try {
      await this.sendRemoveRole(peerId, "webinar_speaker");
      await this.sendRemoveRole(peerId, "interpreter");
    } catch (error) {
      logger.error("removeInterpreterRole() failed: %o", error);
    }
  }

  async changeVisibility(visibility) {
    logger.debug('changeVisibility() [visibility:"%s"]', visibility);

    try {
      await this.sendRequest("moderator:changeVisibility", {
        visibility,
      });
      if (!visibility) {
        this.disableWebcam();
      }
    } catch (error) {
      logger.error("changeVisibility() failed: %o", error);
    }
  }

  configInterpreterSupporting(
    { hasInterpreterSupporting, interpreters, interpreterActiveLanguage },
    isJoin = false
  ) {
    if (interpreterActiveLanguage)
      store.dispatch(
        roomActions.setInterpreterActiveLanguage(interpreterActiveLanguage)
      );
    this.handleInterpreterLanguageObserver(hasInterpreterSupporting);

    if (hasInterpreterSupporting) {
      store.dispatch(roomActions.setInterpreters(interpreters));
      store.dispatch(roomActions.setInterpreterSupporting(true));
      store.dispatch(
        requestActions.notify({
          type: "info",
          text: intl.formatMessage({
            id: "room.interpreterSupportEnabled",
            defaultMessage: "Interpreter support is enabled",
          }),
        })
      );
    } else {
      store.dispatch(roomActions.setInterpreterSupporting(false));
      store.dispatch(meActions.setNativeLanguage(""));
      store.dispatch(roomActions.setInterpreters());
      store.dispatch(roomActions.setInterpreterLanguageApproved(false));
      store.dispatch(peerActions.removeAllNativeLanguage());
      if (!isJoin) {
        store.dispatch(
          requestActions.notify({
            type: "info",
            text: intl.formatMessage({
              id: "room.interpreterSupportDisabled",
              defaultMessage: "Interpreter support is disabled",
            }),
          })
        );
      }
    }
  }

  handleInterpreterLanguageObserver() {
    interpreterLanguageObserverId = setInterval(() => {
      const peers = Object.values(store.getState().peers);
      const me = store.getState().me;
      const activeLanguage = store.getState().room.interpreterActiveLanguage;
      const hasInterpreterSupport = store.getState().room
        .hasInterpreterSupporting;

      if (!hasInterpreterSupport) {
        peers.forEach((peer) => {
          this.modifyPeerConsumer(peer.id, "mic", false);
        });
        clearInterval(interpreterLanguageObserverId);
        return;
      }

      //region If peer is interpreter
      if (me.roles.includes("interpreter")) {
        peers.forEach((peer) => {
          this.modifyPeerConsumer(peer.id, "mic", false);
        });
        return;
      }
      //endregion

      //region Peer consumer data was updated according to native language
      peers
        .filter((peer) => !peer.roles.includes("interpreter"))
        .forEach((peer) => {
          this.modifyPeerConsumer(
            peer.id,
            "mic",
            peer.nativeLanguage !== me.nativeLanguage
          );
        });
      //endregion

      //region consumer was updated for interpreters
      const interpreterIds = Object.values(peers)
        .filter((peer) => peer.roles.includes("interpreter"))
        .map((peer) => peer.id);
      interpreterIds.forEach((peerId) =>
        this.modifyPeerConsumer(
          peerId,
          "mic",
          me.nativeLanguage === activeLanguage
        )
      );
      //endregion
    }, 100);
  }

  async muteAllPeers() {
    logger.debug("muteAllPeers()");

    store.dispatch(roomActions.setMuteAllInProgress(true));

    try {
      await this.sendRequest("moderator:muteAll");
    } catch (error) {
      logger.error("muteAllPeers() failed: %o", error);
    }

    store.dispatch(roomActions.setMuteAllInProgress(false));
  }

  async stopAllPeerVideo() {
    logger.debug("stopAllPeerVideo()");

    store.dispatch(roomActions.setStopAllVideoInProgress(true));

    try {
      await this.sendRequest("moderator:stopAllVideo");
    } catch (error) {
      logger.error("stopAllPeerVideo() failed: %o", error);
    }

    store.dispatch(roomActions.setStopAllVideoInProgress(false));
  }

  // type: mic/webcam/screen

  async makeWebinar(flag) {
    logger.debug("makeWebinar()");

    store.dispatch(roomActions.setRoomWebinar(flag));

    if (store.getState().room.mode !== "virtualClassroom") {
      if (flag) store.dispatch(roomActions.setDisplayMode("filmstrip"));
      else store.dispatch(roomActions.setDisplayMode("democratic"));
    }

    try {
      await this.sendAddRole(this._peerId, "webinar_presenter");
      await this.sendRequest("moderator:makeWebinar", { flag });
    } catch (error) {
      logger.error("makeWebinar() failed: %o", error);
    }
  }

  async closeMeeting() {
    if (this._recording) this.stopRecording();

    logger.debug("closeMeeting()");

    store.dispatch(roomActions.setCloseMeetingInProgress(true));

    try {
      await this.sendRequest("moderator:closeMeeting");
    } catch (error) {
      logger.error("closeMeeting() failed: %o", error);
    }

    store.dispatch(roomActions.setCloseMeetingInProgress(false));
  }

  async modifyPeerMicConsumer(peerId, isMute) {
    const isModerator = store
      .getState()
      .me.roles.some((role) =>
        store.getState().room.permissionsFromRoles.MODERATE_ROOM.includes(role)
      );

    if (isModerator && isMute) {
      this.sendStopPeerMic(peerId);
    } else {
      this.modifyPeerConsumer(peerId, "mic", isMute);
    }
  }

  async modifyPeerWebcamConsumer(peerId, isClose) {
    logger.debug(
      'modifyPeerWebcamConsumer() [peerId:"%s", isClose:"%s"]',
      peerId,
      isClose
    );
    const isModerator = store
      .getState()
      .me.roles.some((role) =>
        store.getState().room.permissionsFromRoles.MODERATE_ROOM.includes(role)
      );

    if (isModerator && isClose) {
      this.sendStopPeerWebcam(peerId);
    } else {
      this.modifyPeerConsumer(peerId, "webcam", isClose);
    }
  }

  // mute: true/false
  async modifyPeerConsumer(peerId, type, mute) {
    logger.debug('modifyPeerConsumer() [peerId:"%s", type:"%s"]', peerId, type);

    if (type === "mic")
      store.dispatch(peerActions.setPeerAudioInProgress(peerId, true));
    else if (type === "webcam")
      store.dispatch(peerActions.setPeerVideoInProgress(peerId, true));
    else if (type === "screen")
      store.dispatch(peerActions.setPeerScreenInProgress(peerId, true));

    try {
      for (const consumer of this._consumers.values()) {
        if (
          consumer.appData.peerId === peerId &&
          consumer.appData.source === type
        ) {
          if (mute) {
            await this._pauseConsumer(consumer);
          } else await this._resumeConsumer(consumer);
        }
      }
    } catch (error) {
      logger.error("modifyPeerConsumer() failed: %o", error);
    }

    if (type === "mic")
      store.dispatch(peerActions.setPeerAudioInProgress(peerId, false));
    else if (type === "webcam")
      store.dispatch(peerActions.setPeerVideoInProgress(peerId, false));
    else if (type === "screen")
      store.dispatch(peerActions.setPeerScreenInProgress(peerId, false));
  }

  async _pauseConsumer(consumer) {
    logger.debug("_pauseConsumer() [consumer: %o]", consumer);

    if (consumer.paused || consumer.closed) return;

    try {
      await this.sendRequest("peer:pauseConsumer", { consumerId: consumer.id });

      consumer.pause();

      store.dispatch(consumerActions.setConsumerPaused(consumer.id, "local"));
    } catch (error) {
      logger.error("_pauseConsumer() | failed:%o", error);
    }
  }

  async _resumeConsumer(consumer) {
    logger.debug("_resumeConsumer() [consumer: %o]", consumer);

    if (!consumer.paused || consumer.closed) return;

    try {
      await this.sendRequest("peer:resumeConsumer", {
        consumerId: consumer.id,
      });

      consumer.resume();

      store.dispatch(consumerActions.setConsumerResumed(consumer.id, "local"));
    } catch (error) {
      logger.error("_resumeConsumer() | failed:%o", error);
    }
  }

  async setParticipantListVisibility(participantListVisibility) {
    try {
      let response = await this.sendRequest(
        "uiAction:setParticipantListVisibility",
        { participantListVisibility: participantListVisibility }
      );
      await store.dispatch(
        settingsActions.setParticipantListVisibility(response)
      );
      if (response) {
        store.dispatch(
          requestActions.notify({
            text: intl.formatMessage({
              id: "settings.participantListVisibilityNotifiy",
              defaultMessage: "Participant list is visible",
            }),
          })
        );
      } else {
        store.dispatch(
          requestActions.notify({
            text: intl.formatMessage({
              id: "settings.participantListUnVisibilityNotifiy",
              defaultMessage: "Participant list is unvisible",
            }),
          })
        );
      }
    } catch (error) {
      logger.error("setParticipantListError() failed: %o", error);
    }
  }

  async getParticipantListVisibility() {
    const meRoles = store.getState().me.roles;
    const webinar = store.getState().room.webinar;
    try {
      let response = await this.sendRequest(
        "uiAction:getParticipantListVisibility"
      );
      await store.dispatch(
        settingsActions.setParticipantListVisibility(response)
      );
      if (!response && webinar && !meRoles.includes("moderator", "admin")) {
        store.dispatch(
          requestActions.notify({
            text: intl.formatMessage({
              id: "settings.participanasdtListVisibilityNotifiy",
              defaultMessage: "Participant List Is Not Active",
            }),
          })
        );
      }
      return response;
    } catch (error) {
      logger.error("getParticipantListError() failed: %o", error);
    }
  }

  async sendRaiseHandState(state) {
    logger.debug("sendRaiseHandState: ", state);

    store.dispatch(meActions.setMyRaiseHandStateInProgress(true));

    try {
      await this.sendRequest("uiAction:raiseHand", { raiseHandState: state });

      store.dispatch(meActions.setMyRaiseHandState(state));
    } catch (error) {
      logger.error("sendRaiseHandState() | failed: %o", error);

      // We need to refresh the component for it to render changed state
      store.dispatch(meActions.setMyRaiseHandState(!state));
    }

    store.dispatch(meActions.setMyRaiseHandStateInProgress(false));
  }

  async sendAddRole(peerId, role) {
    logger.debug("sendAddRole: ", role);

    try {
      await this.sendRequest("moderator:addRole", { peerId, role });
    } catch (error) {
      logger.error("sendAddRole() | failed: %o", error);
    }
  }

  async sendRemoveRole(peerId, role) {
    logger.debug("sendRemoveRole: ", role);

    try {
      await this.sendRequest("moderator:removeRole", { peerId, role });
    } catch (error) {
      logger.error("sendRemoveRole() | failed: %o", error);
    }
  }

  async sendMuteHand(peerId) {
    logger.debug("sendMuteHand: ", peerId);

    try {
      await this.sendRequest("moderator:mutePeerHand", { peerId });
    } catch (error) {
      logger.error("sendMuteHand() | failed: %o", error);
    }
  }

  async sendStopPeerMic(peerId) {
    logger.debug("sendStopPeerMic: ", peerId);

    try {
      await this.sendRequest("moderator:stopPeerMic", { peerId });
    } catch (error) {
      logger.error("sendStopPeerMic() | failed: %o", error);
    }
  }

  async sendStopPeerWebcam(peerId) {
    logger.debug("sendStopPeerWebcam: ", peerId);

    try {
      await this.sendRequest("moderator:stopPeerWebcam", { peerId });
    } catch (error) {
      logger.error("sendStopPeerWebcam() | failed: %o", error);
    }
  }

  async setMaxSendingSpatialLayer(spatialLayer) {
    logger.debug("setMaxSendingSpatialLayer() [spatialLayer:%s]", spatialLayer);

    try {
      if (this._webcamProducer)
        await this._webcamProducer.setMaxSpatialLayer(spatialLayer);
      if (this._screenSharingProducer)
        await this._screenSharingProducer.setMaxSpatialLayer(spatialLayer);
    } catch (error) {
      logger.error('setMaxSendingSpatialLayer() | failed:"%o"', error);
    }
  }

  async setConsumerPreferredLayers(consumerId, spatialLayer, temporalLayer) {
    logger.debug(
      "setConsumerPreferredLayers() [consumerId:%s, spatialLayer:%s, temporalLayer:%s]",
      consumerId,
      spatialLayer,
      temporalLayer
    );

    try {
      await this.sendRequest("peer:setConsumerPreferedLayers", {
        consumerId,
        spatialLayer,
        temporalLayer,
      });

      store.dispatch(
        consumerActions.setConsumerPreferredLayers(
          consumerId,
          spatialLayer,
          temporalLayer
        )
      );
    } catch (error) {
      logger.error('setConsumerPreferredLayers() | failed:"%o"', error);
    }
  }

  async setConsumerPriority(consumerId, priority) {
    logger.debug(
      "setConsumerPriority() [consumerId:%s, priority:%d]",
      consumerId,
      priority
    );

    try {
      await this.sendRequest("peer:setConsumerPriority", {
        consumerId,
        priority,
      });

      store.dispatch(consumerActions.setConsumerPriority(consumerId, priority));
    } catch (error) {
      logger.error("setConsumerPriority() | failed:%o", error);
    }
  }

  async requestConsumerKeyFrame(consumerId) {
    logger.debug("requestConsumerKeyFrame() [consumerId:%s]", consumerId);

    try {
      await this.sendRequest("peer:requestConsumerKeyFrame", { consumerId });
    } catch (error) {
      logger.error("requestConsumerKeyFrame() | failed:%o", error);
    }
  }

  async _loadDynamicImports() {
    ({ default: saveAs } = await import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "file-saver" */
      "file-saver"
    ));

    ({ default: ScreenShare } = await import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "screensharing" */
      "./ScreenShare"
    ));

    ({ default: Spotlights } = await import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "spotlights" */
      "./Spotlights"
    ));

    mediasoupClient = await import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "mediasoup" */
      "mediasoup-client"
    );

    ({ default: io } = await import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "socket.io" */
      "socket.io-client"
    ));
  }

  async join({ roomId, joinVideo, joinAudio }) {
    await this._loadDynamicImports();

    this.resetVolumeLevel();

    this._roomId = roomId;

    store.dispatch(roomActions.setRoomName(roomId));

    this._signalingUrl = getSignalingUrl(this._peerId, roomId);

    this._screenSharing = ScreenShare.create(this._device);

    this._screenSharingForRecorder = ScreenShare.create(this._device);

    this._signalingSocket = io(this._signalingUrl, {
      transports: ["websocket"],
      auth: {
        userToken: sessionStorage.getItem("token"),
      },
    });

    this._spotlights = new Spotlights(
      this._maxSpotlights,
      this._signalingSocket
    );

    //Redux may keep state unless the browser window is incognito.Set it to false
    if (store.getState().room.whiteBoardEnabled) {
      store.dispatch(roomActions.setWhiteBoardEnabled());
    }

    store.dispatch(roomActions.setRoomState("connecting"));

    this._signalingSocket.on("connect", () => {
      logger.debug('signaling Peer "connect" event');
    });

    this._signalingSocket.on("disconnect", (reason) => {
      logger.warn('signaling Peer "disconnect" event [reason:"%s"]', reason);

      if (this._closed) return;

      if (reason === "io server disconnect") {
        store.dispatch(
          requestActions.notify({
            text: intl.formatMessage({
              id: "socket.disconnected",
              defaultMessage: "You are disconnected",
            }),
          })
        );

        this.close();
      }

      store.dispatch(
        requestActions.notify({
          text: intl.formatMessage({
            id: "socket.reconnecting",
            defaultMessage: "You are disconnected, attempting to reconnect",
          }),
        })
      );

      if (this._screenSharingProducer) {
        this._screenSharingProducer.close();

        store.dispatch(
          producerActions.removeProducer(this._screenSharingProducer.id)
        );

        this._screenSharingProducer = null;
      }

      if (this._screenSharingAudioProducer) {
        this._screenSharingAudioProducer.close();

        store.dispatch(
          producerActions.removeProducer(this._screenSharingAudioProducer.id)
        );

        this._screenSharingAudioProducer = null;
      }

      if (this._webcamProducer) {
        this._webcamProducer.close();

        store.dispatch(producerActions.removeProducer(this._webcamProducer.id));

        this._webcamProducer = null;
      }

      if (this._micProducer) {
        this._micProducer.close();

        store.dispatch(producerActions.removeProducer(this._micProducer.id));

        this._micProducer = null;
      }

      if (this._sendTransport) {
        this._sendTransport.close();

        this._sendTransport = null;
      }

      if (this._recvTransport) {
        this._recvTransport.close();

        this._recvTransport = null;
      }

      store.dispatch(roomActions.setRoomState("connecting"));
    });

    this._signalingSocket.on("reconnect_failed", () => {
      logger.warn('signaling Peer "reconnect_failed" event');

      store.dispatch(
        requestActions.notify({
          text: intl.formatMessage({
            id: "socket.disconnected",
            defaultMessage: "You are disconnected",
          }),
        })
      );

      this.close();
    });

    this._signalingSocket.on("reconnect", (attemptNumber) => {
      logger.debug(
        'signaling Peer "reconnect" event [attempts:"%s"]',
        attemptNumber
      );

      store.dispatch(
        requestActions.notify({
          text: intl.formatMessage({
            id: "socket.reconnected",
            defaultMessage: "You are reconnected",
          }),
        })
      );

      store.dispatch(roomActions.setRoomState("connected"));
    });

    this._signalingSocket.on("request", async (request, cb) => {
      logger.debug(
        'socket "request" event [method:%s, data:%o]',
        request.method,
        request.data
      );

      switch (request.method) {
        case "newConsumer": {
          const {
            peerId,
            producerId,
            id,
            kind,
            rtpParameters,
            type,
            appData,
            producerPaused,
          } = request.data;

          let codecOptions;

          if (kind === "audio") {
            codecOptions = {
              opusStereo: false,
              opusDtx: true,
              opusFec: true,
              opusMaxPlaybackRate: 48000,
            };
          }

          const consumer = await this._recvTransport.consume({
            id,
            producerId,
            kind,
            rtpParameters,
            codecOptions,
            appData: { ...appData, peerId }, // Trick.
          });

          // Store in the map.
          this._consumers.set(consumer.id, consumer);

          consumer.on("transportclose", () => {
            this._consumers.delete(consumer.id);
          });

          const {
            spatialLayers,
            temporalLayers,
          } = mediasoupClient.parseScalabilityMode(
            consumer.rtpParameters.encodings[0].scalabilityMode
          );

          store.dispatch(
            consumerActions.addConsumer(
              {
                id: consumer.id,
                peerId: peerId,
                kind: kind,
                type: type,
                locallyPaused: false,
                remotelyPaused: producerPaused,
                rtpParameters: consumer.rtpParameters,
                source: consumer.appData.source,
                spatialLayers: spatialLayers,
                temporalLayers: temporalLayers,
                preferredSpatialLayer: spatialLayers - 1,
                preferredTemporalLayer: temporalLayers - 1,
                priority: 1,
                codec: consumer.rtpParameters.codecs[0].mimeType.split("/")[1],
                track: consumer.track,
                consumer: consumer,
              },
              peerId
            )
          );

          // We are ready. Answer the request so the server will
          // resume this Consumer (which was paused for now).
          cb(null);

          if (kind === "audio") {
            const rstream = new MediaStream();
            rstream.addTrack(consumer.track);
            this.appendRecording(rstream);

            //consumer.volume = 0;

            const stream = new MediaStream();

            stream.addTrack(consumer.track);

            if (!stream.getAudioTracks()[0])
              throw new Error(
                "request.newConsumer | given stream has no audio track"
              );

            consumer.hark = hark(stream, { play: false });

            // eslint-disable-next-line no-unused-vars
            consumer.hark.on("volume_change", (dBs, threshold) => {
              // The exact formula to convert from dBs (-100..0) to linear (0..1) is:
              //   Math.pow(10, dBs / 20)
              // However it does not produce a visually useful output, so let exagerate
              // it a bit. Also, let convert it from 0..1 to 0..10 and avoid value 1 to
              // minimize component renderings.
              let volume = Math.round(Math.pow(10, dBs / 85) * 10);

              if (volume === 1) volume = 0;

              volume = Math.round(volume);

              if (consumer && volume !== consumer.volume) {
                consumer.volume = volume;
                store.dispatch(peerVolumeActions.setPeerVolume(peerId, volume));
              }
            });
          }

          break;
        }
        case "newDataConsumer": {
          const {
            peerId,
            dataProducerId,
            id,
            sctpStreamParameters,
            label,
            protocol,
            appData,
          } = request.data;

          if (!this._useDataChannel) {
            logger.warn("No Data Channel!");
            break;
          }

          try {
            let secretKey = this._generateKey();
            this._secretKeysSent.set(peerId, secretKey);
            await this.sendRequest("security:shareSecretKey", {
              peerId,
              secretKey,
            });
          } catch (error) {
            logger.error("Secret Key cannot be generated: [%o]", error);
          }

          try {
            const dataConsumer = await this._recvTransport.consumeData({
              id,
              dataProducerId,
              sctpStreamParameters,
              label,
              protocol,
              appData: { ...appData, peerId }, // Trick.
            });

            // Store in the map.
            this._dataConsumers.set(dataConsumer.id, dataConsumer);

            store.dispatch(
              dataConsumerActions.addDataConsumer(
                {
                  id: dataConsumer.id,
                  sctpStreamParameters: dataConsumer.sctpStreamParameters,
                  label: dataConsumer.label,
                  protocol: dataConsumer.protocol,
                },
                peerId
              )
            );

            dataConsumer.on("transportclose", () => {
              logger.debug('DataConsumer "transportclose" event');
              this._dataConsumers.delete(dataConsumer.id);
            });

            dataConsumer.on("open", () => {
              logger.debug('DataConsumer "open" event');
            });

            dataConsumer.on("close", () => {
              logger.debug('DataConsumer "close" event');
              this._dataConsumers.delete(dataConsumer.id);
            });

            dataConsumer.on("error", (error) => {
              logger.error('DataConsumer "error" event:%o', error);
            });

            dataConsumer.on("message", (message) => {
              logger.debug(
                'DataConsumer "message" event [streamId:%d]',
                dataConsumer.sctpStreamParameters.streamId
              );

              const { peers } = store.getState();
              const peersArray = Object.keys(peers).map(
                (_peerId) => peers[_peerId]
              );

              const sendingPeer = peersArray.find((peer) =>
                peer.dataConsumers.includes(dataConsumer.id)
              );

              if (!sendingPeer) {
                logger.warn('DataConsumer "message" from unknown peer');
              }
              const {
                text,
                name,
                sender,
                recipient,
                recipientDisplayName,
                senderId,
                isUnread,
              } = JSON.parse(message);
              const decryptedText =
                recipient !== "everyone"
                  ? this._decrypt_message(sendingPeer, text).slice(1, -1)
                  : text;

              const newMessage = createNewMessage(
                decryptedText,
                sender,
                name,
                undefined,
                recipient,
                recipientDisplayName,
                senderId,
                isUnread
              );
              const messages = [newMessage];
              this.onChatMessage(messages);
            });
            cb(null);
          } catch (error) {
            logger.error('"newDataConsumer" request failed:%o', error);

            throw error;
          }
          break;
        }
        default: {
          logger.error('unknown request.method "%s"', request.method);

          cb(500, `unknown request.method "${request.method}"`);
        }
      }
    });

    this._signalingSocket.on("notification", async (notification) => {
      //console.log('Notification IN.');
      const notificationInTime = new Date().getMilliseconds();
      logger.debug(
        'socket "notification" event [method:%s, data:%o]',
        notification.method,
        notification.data
      );

      try {
        switch (notification.method) {
          case "enteredLobby": {
            store.dispatch(roomActions.setInLobby(true));
            store.dispatch(userActions.setJoinStep(JoinSteps.LOBBY));

            const { displayName } = store.getState().settings;
            const { picture } = store.getState().me;

            await this.sendRequest("uiAction:changeDisplayName", {
              displayName,
            });
            await this.sendRequest("changePicture", { picture });
            break;
          }

          case "signInRequired": {
            store.dispatch(roomActions.setSignInRequired(true));

            break;
          }

          case "roomReady": {
            store.dispatch(roomActions.toggleJoined());
            store.dispatch(roomActions.setInLobby(false));
            store.dispatch(userActions.setJoinStep(JoinSteps.COMPLETED));

            await this._joinRoom({ joinVideo, joinAudio });

            break;
          }

          case "roomBack": {
            await this._joinRoom({ joinVideo, joinAudio });

            break;
          }
          case "raiseHand": {
            const { peerId, raisedHand, raiseHandIndex } = notification.data;
            if (store.getState().me.id === peerId) {
              store.dispatch(
                meActions.setMyRaiseHandState(raisedHand, raiseHandIndex)
              );
            } else {
              store.dispatch(
                peerActions.setPeerRaiseHandState(
                  peerId,
                  raisedHand,
                  raiseHandIndex
                )
              );
            }
            break;
          }

          case "lockRoom": {
            store.dispatch(roomActions.setRoomLocked());

            store.dispatch(
              requestActions.notify({
                text: intl.formatMessage({
                  id: "room.locked",
                  defaultMessage: "Room is now locked",
                }),
              })
            );

            break;
          }

          case "unlockRoom": {
            store.dispatch(roomActions.setRoomUnLocked());

            store.dispatch(
              requestActions.notify({
                text: intl.formatMessage({
                  id: "room.unlocked",
                  defaultMessage: "Room is now unlocked",
                }),
              })
            );

            break;
          }

          case "parkedPeer": {
            const { id, displayName, picture } = notification.data;

            store.dispatch(lobbyPeerActions.addLobbyPeer(id));
            store.dispatch(
              lobbyPeerActions.setLobbyPeerDisplayName(displayName, id)
            );
            store.dispatch(lobbyPeerActions.setLobbyPeerPicture(picture));

            store.dispatch(roomActions.setToolbarsVisible(true));

            if (store.getState().me.roles.includes("moderator")) {
              store.dispatch(
                requestActions.notify({
                  text: intl.formatMessage({
                    id: "room.newLobbyPeer",
                    defaultMessage: "New participant in lobby",
                  }),
                })
              );
            }
            break;
          }

          case "lobby:peerClosed": {
            const { peerId } = notification.data;

            if (
              store.getState().me.roles.includes("moderator") &&
              Object.keys(store.getState().lobbyPeers).includes(peerId)
            ) {
              store.dispatch(
                requestActions.notify({
                  text: intl.formatMessage({
                    id: "room.lobbyPeerLeft",
                    defaultMessage: "Participant left the lobby",
                  }),
                })
              );
            }

            store.dispatch(lobbyPeerActions.removeLobbyPeer(peerId));

            break;
          }

          case "lobby:promotedPeer": {
            const { peerId } = notification.data;

            store.dispatch(lobbyPeerActions.removeLobbyPeer(peerId));

            break;
          }

          case "lobby:changeDisplayName": {
            const { peerId, displayName } = notification.data;

            store.dispatch(
              lobbyPeerActions.setLobbyPeerDisplayName(displayName, peerId)
            );

            break;
          }

          case "lobby:changePicture": {
            const { peerId, picture } = notification.data;

            store.dispatch(
              lobbyPeerActions.setLobbyPeerPicture(picture, peerId)
            );

            if (store.getState().me.roles.includes("moderator")) {
              store.dispatch(
                requestActions.notify({
                  text: intl.formatMessage({
                    id: "room.lobbyPeerChangedPicture",
                    defaultMessage: "Participant in lobby changed picture",
                  }),
                })
              );
            }
            break;
          }

          case "setAccessCode": {
            const { accessCode } = notification.data;

            store.dispatch(roomActions.setAccessCode(accessCode));

            store.dispatch(
              requestActions.notify({
                text: intl.formatMessage({
                  id: "room.setAccessCode",
                  defaultMessage: "Access code for room updated",
                }),
              })
            );

            break;
          }

          case "setJoinByAccessCode": {
            const { joinByAccessCode } = notification.data;

            store.dispatch(roomActions.setJoinByAccessCode(joinByAccessCode));

            if (joinByAccessCode) {
              store.dispatch(
                requestActions.notify({
                  text: intl.formatMessage({
                    id: "room.accessCodeOn",
                    defaultMessage: "Access code for room is now activated",
                  }),
                })
              );
            } else {
              store.dispatch(
                requestActions.notify({
                  text: intl.formatMessage({
                    id: "room.accessCodeOff",
                    defaultMessage: "Access code for room is now deactivated",
                  }),
                })
              );
            }

            break;
          }

          case "activeSpeaker": {
            const { peerId } = notification.data;

            store.dispatch(roomActions.setRoomActiveSpeaker(peerId));

            if (peerId && peerId !== this._peerId)
              this._spotlights.handleActiveSpeaker(peerId);

            break;
          }

          case "changeDisplayName": {
            const { peerId, displayName, oldDisplayName } = notification.data;

            store.dispatch(peerActions.setPeerDisplayName(displayName, peerId));

            store.dispatch(
              requestActions.notify({
                text: intl.formatMessage(
                  {
                    id: "room.peerChangedDisplayName",
                    defaultMessage: "{oldDisplayName} is now {displayName}",
                  },
                  {
                    oldDisplayName,
                    displayName,
                  }
                ),
              })
            );

            break;
          }

          case "changePicture": {
            const { peerId, picture } = notification.data;

            store.dispatch(peerActions.setPeerPicture(peerId, picture));

            break;
          }

          case "chatMessage": {
            const chatMessages = notification.data.filter(
              (c) => c.peerId !== this._peerId
            );

            this.onChatMessage(chatMessages);

            break;
          }
          case "whiteBoardEvent": {
            const { peerId, event } = notification.data;
            store.dispatch(whiteBoardActions.setData(event.data));

            if (!store.getState().whiteboard.whiteBoardOpen) {
              store.dispatch(roomActions.setToolbarsVisible(true));
              if (store.getState().settings.whiteboardSound) {
                this._soundNotification();
              }
            }
            break;
          }

          case "sendFile": {
            const { peerId, magnetUri } = notification.data;

            store.dispatch(fileActions.addFile(peerId, magnetUri));

            store.dispatch(
              requestActions.notify({
                text: intl.formatMessage({
                  id: "room.newFile",
                  defaultMessage: "New file available",
                }),
              })
            );

            if (
              !store.getState().toolarea.toolAreaOpen ||
              (store.getState().toolarea.toolAreaOpen &&
                store.getState().toolarea.currentToolTab !== "files")
            ) {
              // Make sound
              store.dispatch(roomActions.setToolbarsVisible(true));
              if (store.getState().settings.chatSound) {
                this._soundNotification();
              }
            }

            break;
          }

          case "producerScore": {
            const { producerId, score } = notification.data;

            store.dispatch(producerActions.setProducerScore(producerId, score));

            break;
          }

          case "newPeer": {
            const {
              id,
              displayName,
              picture,
              roles,
              screenPin,
              screenPinIndex,
              raiseHandIndex,
              visibility,
            } = notification.data;

            store.dispatch(
              peerActions.addPeer({
                id,
                displayName,
                picture,
                roles,
                consumers: [],
                screenPin,
                screenPinIndex,
                raiseHandIndex,
                visibility,
              })
            );

            let webinar = store.getState().room.webinar;
            if (!webinar)
              store.dispatch(
                requestActions.notify({
                  text: intl.formatMessage(
                    {
                      id: "room.newPeer",
                      defaultMessage: "{displayName} joined the room",
                    },
                    {
                      displayName,
                    }
                  ),
                })
              );

            if (store.getState().settings.newPeerSound) {
              this._soundNotification();
            }

            break;
          }

          case "peerClosed": {
            const { peerId } = notification.data;

            store.dispatch(peerActions.removePeer(peerId));

            break;
          }

          case "consumerClosed": {
            const { consumerId } = notification.data;
            const consumer = this._consumers.get(consumerId);

            if (!consumer) break;

            consumer.close();

            if (consumer.hark != null) consumer.hark.stop();

            this._consumers.delete(consumerId);

            const { peerId } = consumer.appData;

            store.dispatch(consumerActions.removeConsumer(consumerId, peerId));

            break;
          }

          case "consumerPaused": {
            const { consumerId } = notification.data;
            const consumer = this._consumers.get(consumerId);

            if (!consumer) break;

            store.dispatch(
              consumerActions.setConsumerPaused(consumerId, "remote")
            );

            break;
          }

          case "consumerResumed": {
            const { consumerId } = notification.data;
            const consumer = this._consumers.get(consumerId);

            if (!consumer) break;

            store.dispatch(
              consumerActions.setConsumerResumed(consumerId, "remote")
            );

            break;
          }

          case "consumerLayersChanged": {
            const {
              consumerId,
              spatialLayer,
              temporalLayer,
            } = notification.data;
            const consumer = this._consumers.get(consumerId);

            if (!consumer) break;

            store.dispatch(
              consumerActions.setConsumerCurrentLayers(
                consumerId,
                spatialLayer,
                temporalLayer
              )
            );

            break;
          }

          case "consumerScore": {
            const { consumerId, score } = notification.data;

            store.dispatch(consumerActions.setConsumerScore(consumerId, score));

            break;
          }

          case "dataConsumerClosed": {
            const { dataConsumerId } = notification.data;
            const dataConsumer = this._dataConsumers.get(dataConsumerId);

            if (!dataConsumer) break;

            dataConsumer.close();
            this._dataConsumers.delete(dataConsumerId);

            const { peerId } = dataConsumer.appData;

            store.dispatch(
              dataConsumerActions.removeDataConsumer(dataConsumerId, peerId)
            );
            break;
          }

          case "moderator:mute": {
            // const { peerId } = notification.data;

            if (this._micProducer && !this._micProducer.paused) {
              this.muteMic();

              store.dispatch(
                requestActions.notify({
                  text: intl.formatMessage({
                    id: "moderator.mute",
                    defaultMessage: "Moderator muted your microphone",
                  }),
                })
              );
            }

            break;
          }

          case "moderator:enableWhiteboard": {
            const { enableWhiteboard } = notification.data;
            if (enableWhiteboard !== store.getState().room.whiteBoardEnabled) {
              store.dispatch(roomActions.setWhiteBoardEnabled());
            }
            break;
          }

          case "moderator:stopVideo": {
            // const { peerId } = notification.data;

            this.disableWebcam();
            this.disableScreenSharing();

            store.dispatch(
              requestActions.notify({
                text: intl.formatMessage({
                  id: "moderator.stopVideo",
                  defaultMessage: "Moderator stopped your video",
                }),
              })
            );

            break;
          }

          case "moderator:stopMic": {
            this.muteMic();
            store.dispatch(
              requestActions.notify({
                text: intl.formatMessage({
                  id: "moderator.stopMic",
                  defaultMessage: "Moderator stopped your microphone",
                }),
              })
            );

            break;
          }

          case "moderator:suspendMeeting": {
            const { meetingSuspended } = notification.data;

            let id = "moderator.suspendMeeting";
            let message = "The meeting has been suspended";

            if (meetingSuspended) {
              await this.suspendMeeting(meetingSuspended);
            } else {
              id = "moderator.unsuspendMeeting";
              message = "The meeting has been unsuspended";
              await this.unsuspendMeeting(meetingSuspended);
            }

            store.dispatch(
              requestActions.notify({
                text: intl.formatMessage({
                  id: id,
                  defaultMessage: message,
                }),
              })
            );

            break;
          }

          case "moderator:stopWebcam": {
            this.disableWebcam();
            store.dispatch(
              requestActions.notify({
                text: intl.formatMessage({
                  id: "moderator.stopWebcam",
                  defaultMessage: "Moderator stopped your webcam",
                }),
              })
            );

            break;
          }

          case "moderator:room:webinar": {
            const { peerId, flag } = notification.data;

            if (flag) {
              store.dispatch(roomActions.setDisplayMode("filmstrip"));

              if (this._micProducer && !this._micProducer.paused)
                this.muteMic();

              this.disableWebcam();
              this.disableScreenSharing();

              store.dispatch(
                requestActions.notify({
                  text: intl.formatMessage({
                    id: "moderator.hasMakeWebinarThisRoom",
                    defaultMessage: "Moderator has made webinar this room",
                  }),
                })
              );
            } else {
              // cancel webinar
              store.dispatch(roomActions.setDisplayMode("democratic"));
              store.dispatch(
                requestActions.notify({
                  text: intl.formatMessage({
                    id: "moderator.cancelledWebinarForThisRoom",
                    defaultMessage: "Moderator cancelled webinar for this room",
                  }),
                })
              );
            }

            store.dispatch(roomActions.setRoomWebinar(flag));

            break;
          }

          case "moderator:mutePeerHand": {
            const { peerId } = notification.data;
            if (peerId === this._peerId) {
              this.sendRaiseHandState(false);
              store.dispatch(meActions.setMyRaiseHandState(false));
            }
            break;
          }

          case "moderator:kick": {
            // Need some feedback
            this.close();

            break;
          }

          case "gotRole": {
            const { peerId, role } = notification.data;

            if (peerId === this._peerId) {
              store.dispatch(meActions.addRole(role));

              if (role === "webinar_speaker") {
                this.sendRaiseHandState(false);
                store.dispatch(meActions.setMyRaiseHandState(false));
                store.dispatch(
                  requestActions.notify({
                    text: intl.formatMessage({
                      id: "roles.webinarSpeaker",
                      defaultMessage: `You got the webinar speaker role`,
                    }),
                  })
                );
              } else if (role === "webinar_presenter") {
                store.dispatch(
                  requestActions.notify({
                    text: intl.formatMessage({
                      id: "roles.roomIsNowWebinar",
                      defaultMessage: `Room is now Webinar`,
                    }),
                  })
                );
              } else
                store.dispatch(
                  requestActions.notify({
                    text: intl.formatMessage(
                      {
                        id: "roles.gotRole",
                        defaultMessage: `You got the role: ${role}`,
                      },
                      { role }
                    ),
                  })
                );
            } else store.dispatch(peerActions.addPeerRole(peerId, role));

            // if (store.getState().room.hasInterpreterSupporting)
            //   this.interpreterMicSettings(true);

            break;
          }

          case "lostRole": {
            const { peerId, role } = notification.data;

            if (peerId === this._peerId) {
              store.dispatch(meActions.removeRole(role));

              if (role === "webinar_speaker") {
                if (this._micProducer && !this._micProducer.paused)
                  this.muteMic();

                this.disableWebcam();
                this.disableScreenSharing();
                store.dispatch(
                  requestActions.notify({
                    text: intl.formatMessage({
                      id: "roles.cancelledWebinarSpeaker",
                      defaultMessage: `You got the webinar speaker role`,
                    }),
                  })
                );
              } else
                store.dispatch(
                  requestActions.notify({
                    text: intl.formatMessage({
                      id: "roles.lostRole",
                      defaultMessage: `You lost the role: ${role}`,
                    }),
                  })
                );
            } else store.dispatch(peerActions.removePeerRole(peerId, role));

            // if (store.getState().room.hasInterpreterSupporting)
            //   this.interpreterMicSettings(true);
            break;
          }

          case "setRecordingNotificationStatus": {
            const { recordingNotificationStatus } = notification.data;
            store.dispatch(
              roomActions.setRecordingNotificationStatus(
                recordingNotificationStatus
              )
            );
            break;
          }

          case "secretKey": {
            const { sendingPeerId, secretKey } = notification.data;
            this._secretKeysReceived.set(sendingPeerId, secretKey);
            break;
          }

          case "screenPin": {
            const { peerId, flag, screenPinIndex } = notification.data;

            if (peerId === this._peerId) break;

            store.dispatch(
              peerActions.toggleScreenPin(peerId, flag, screenPinIndex)
            );

            const pinnedPeers = Object.values(store.getState().peers).filter(
              (peer) => peer.screenPin
            );
            this._spotlights.pinnedPeers = pinnedPeers.map((peer) => peer.id);

            if (
              flag &&
              pinnedPeers.length - 1 === this._spotlights.maxSpotlights
            ) {
              this.changeMaxSpotlights(this._spotlights.maxSpotlights + 1);
            }

            break;
          }

          case "moderator:interpreterSupporting": {
            this.configInterpreterSupporting(notification.data);
            break;
          }

          case "peer:setNativeLanguage": {
            const { peerId, nativeLanguage } = notification.data;
            store.dispatch(
              peerActions.setNativeLanguage(peerId, nativeLanguage)
            );
            //this.interpreterMicSettings(true);
            break;
          }

          case "room:interpreterActiveLanguage": {
            const { interpreterActiveLanguage } = notification.data;
            store.dispatch(
              roomActions.setInterpreterActiveLanguage(
                interpreterActiveLanguage
              )
            );
            break;
          }

          case "moderator:changeRoomPassword": {
            const { password } = notification.data;
            const beforePassword = store.getState().room.password;
            store.dispatch(roomActions.setPassword(password));

            if (beforePassword) {
              if (password) {
                store.dispatch(
                  requestActions.notify({
                    type: "info",
                    text: intl.formatMessage({
                      id: "room.updatedRoomPassword",
                      defaultMessage: "Room password is updated",
                    }),
                  })
                );
                store.dispatch(roomActions.setHasPassword(true));
              } else {
                store.dispatch(
                  requestActions.notify({
                    type: "info",
                    text: intl.formatMessage({
                      id: "room.removedRoomPassword",
                      defaultMessage: "Room password is removed",
                    }),
                  })
                );
                store.dispatch(roomActions.setHasPassword(false));
              }
            } else {
              store.dispatch(
                requestActions.notify({
                  type: "info",
                  text: intl.formatMessage({
                    id: "room.enableRoomPassword",
                    defaultMessage: "The room password is generated",
                  }),
                })
              );
              store.dispatch(roomActions.setHasPassword(true));
            }
            break;
          }

          case "moderator:changeVisibility": {
            const { peerId, visibility } = notification.data;
            if (store.getState().me.id === peerId) {
              store.dispatch(meActions.setVisibility(visibility));
            } else {
              store.dispatch(peerActions.setVisibility(peerId, visibility));
            }
            break;
          }
          case "license:expiredExpirationDate": {
            store.dispatch(
              requestActions.notify({
                type: "error",
                text: intl.formatMessage({
                  id: "licence.expiredExpirationDate",
                  defaultMessage:
                    "Product license is expired. Please contact your client representative.",
                }),
              })
            );
            break;
          }
          case "license:roomLimitExceeded": {
            store.dispatch(
              requestActions.notify({
                type: "error",
                text: intl.formatMessage({
                  id: "licence.roomLimitExceeded",
                  defaultMessage:
                    "Maximum room limit exceeded. Please contact your client representative.",
                }),
              })
            );
            break;
          }
          case "license:roomFull": {
            const { isModerator } = notification.data;
            if (isModerator) {
              store.dispatch(
                requestActions.notify({
                  type: "error",
                  text: intl.formatMessage({
                    id: "licence.roomFull.moderator",
                    defaultMessage:
                      "Room capacity is full. Last participant is closing.",
                  }),
                })
              );
            } else {
              store.dispatch(
                requestActions.notify({
                  type: "error",
                  text: intl.formatMessage({
                    id: "licence.roomFull.peer",
                    defaultMessage:
                      "Room capacity is full. Please try again later.",
                  }),
                })
              );
            }

            break;
          }
          case "peer:maxNumberOfPeerExceeded": {
            store.dispatch(
              requestActions.notify({
                type: "error",
                text: intl.formatMessage({
                  id: "room.maxNumberOfPeerExceeded",
                  defaultMessage:
                    "Maximum number of participant exceeded. Meeting is closing.",
                }),
              })
            );
            break;
          }
          case "moderator:changeMaxNumberOfPeer": {
            const maxPeerSize = notification.data;
            store.dispatch(roomActions.setMaxNumberOfPeer(maxPeerSize));
            store.dispatch(
              requestActions.notify({
                type: "info",
                text: intl.formatMessage({
                  id: "room.maxNumberOfPeerChanged",
                  defaultMessage: "Maximum number of participant changed",
                }),
              })
            );
          }
          case "moderator:changeMaxNumberOfVideo": {
            const { maxNumberOfVideo } = notification.data;
            store.dispatch(roomActions.setMaxNumberOfVideo(maxNumberOfVideo));
          }
          default: {
            logger.error(
              'unknown notification.method "%s"',
              notification.method
            );
          }
        }
      } catch (error) {
        logger.error('error on socket "notification" event failed:"%o"', error);

        store.dispatch(
          requestActions.notify({
            type: "error",
            text: intl.formatMessage({
              id: "socket.requestError",
              defaultMessage: "Error on server request",
            }),
          })
        );
      }
      //console.log('Notification OUT. Time elapsed: ' + (new Date().getMilliseconds() - notificationInTime) + 'ms.');
    });
  }

  async setRoomPassword(password) {
    logger.debug("setRoomPassword() [password:%s]", password);
    try {
      await this.sendRequest("moderator:setRoomPassword", {
        roomId: store.getState().room.name,
        password,
      });
    } catch (error) {
      logger.error("setRoomPassword() | failed:%o", error);
      this.dispatchNotification(
        "room.roomPasswordRequestError",
        "error",
        "Check Room Password Request Error!"
      );
    }
  }

  async _joinRoom({ joinVideo, joinAudio }) {
    logger.debug("_joinRoom()");

    const { displayName } = store.getState().settings;
    const { picture } = store.getState().me;

    try {
      this._mediasoupDevice = new mediasoupClient.Device();

      const routerRtpCapabilities = await this.sendRequest(
        "connection:getRouterRtpCapabilities"
      );

      await this._mediasoupDevice.load({ routerRtpCapabilities });

      if (this._produce) {
        const transportInfo = await this.sendRequest(
          "connection:createWebRtcTransport",
          {
            forceTcp: this._forceTcp,
            producing: true,
            consuming: false,
            sctpCapabilities: this._mediasoupDevice.sctpCapabilities,
          }
        );

        const {
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          sctpParameters,
        } = transportInfo;

        this._sendTransport = this._mediasoupDevice.createSendTransport({
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          sctpParameters,
          iceServers: await this.getTurnServerConfigs(),
          proprietaryConstraints: PC_PROPRIETARY_CONSTRAINTS,
        });

        this._sendTransport.on(
          "connect",
          (
            { dtlsParameters },
            callback,
            errback // eslint-disable-line no-shadow
          ) => {
            this.sendRequest("connection:connectWebRtcTransport", {
              transportId: this._sendTransport.id,
              dtlsParameters,
            })
              .then(callback)
              .catch(errback);
          }
        );

        this._sendTransport.on(
          "produce",
          async ({ kind, rtpParameters, appData }, callback, errback) => {
            try {
              // eslint-disable-next-line no-shadow
              const { id } = await this.sendRequest("connection:produce", {
                transportId: this._sendTransport.id,
                kind,
                rtpParameters,
                appData,
              });

              callback({ id });
            } catch (error) {
              errback(error);
            }
          }
        );
        this._sendTransport.on(
          "producedata",
          async (
            { sctpStreamParameters, label, protocol, appData },
            callback,
            errback
          ) => {
            logger.debug(
              '"producedata" event: [sctpStreamParameters:%o, appData:%o]',
              sctpStreamParameters,
              appData
            );

            try {
              // eslint-disable-next-line no-shadow
              const { id } = await this.sendRequest("connection:produceData", {
                transportId: this._sendTransport.id,
                sctpStreamParameters,
                label,
                protocol,
                appData,
              });

              callback({ id });
            } catch (error) {
              errback(error);
            }
          }
        );
        this._sendTransport.on("connectionstatechange", (connectionState) => {
          logger.debug(
            '"connectionstatechange" event: [connectionState:%o]',
            connectionState
          );
          this._connectionState = connectionState;
        });
      }

      const transportInfo = await this.sendRequest(
        "connection:createWebRtcTransport",
        {
          forceTcp: this._forceTcp,
          producing: false,
          consuming: true,
          sctpCapabilities: this._mediasoupDevice.sctpCapabilities,
        }
      );

      const {
        id,
        iceParameters,
        iceCandidates,
        dtlsParameters,
        sctpParameters,
      } = transportInfo;

      this._recvTransport = this._mediasoupDevice.createRecvTransport({
        id,
        iceParameters,
        iceCandidates,
        dtlsParameters,
        sctpParameters,
        iceServers: await this.getTurnServerConfigs(),
      });

      this._recvTransport.on(
        "connect",
        (
          { dtlsParameters },
          callback,
          errback // eslint-disable-line no-shadow
        ) => {
          this.sendRequest("connection:connectWebRtcTransport", {
            transportId: this._recvTransport.id,
            dtlsParameters,
          })
            .then(callback)
            .catch(errback);
        }
      );

      // Set our media capabilities.
      store.dispatch(
        meActions.setMediaCapabilities({
          canSendMic: this._mediasoupDevice.canProduce("audio"),
          canSendWebcam: this._mediasoupDevice.canProduce("video"),
          canShareScreen:
            this._mediasoupDevice.canProduce("video") &&
            this._screenSharing.isScreenShareAvailable(),
          canShareFiles: false,
        })
      );

      const {
        roles,
        peers,
        authenticated,
        permissionsFromRoles,
        userRoles,
        pinnedPeerIds,
        interpreterInfo,
        password,
        roomKey,
        maxPeerSize,
        maxNumberOfVideo,
      } = await this.sendRequest("connection:join", {
        displayName: displayName,
        picture: picture,
        rtpCapabilities: this._mediasoupDevice.rtpCapabilities,
        sctpCapabilities: this._mediasoupDevice.sctpCapabilities,
        roomPassword: store.getState().room.password,
      });

      store.dispatch(roomActions.setMaxNumberOfVideo(maxNumberOfVideo));
      store.dispatch(roomActions.setMaxNumberOfPeer(maxPeerSize));
      store.dispatch(roomActions.setRoomKey(roomKey));

      sessionStorage.setItem(
        "interpreterActiveLanguage",
        interpreterInfo.interpreterActiveLanguage
      );
      this.configInterpreterSupporting(interpreterInfo, true);

      store.dispatch(meActions.loggedIn(authenticated));
      store.dispatch(roomActions.setUserRoles(userRoles));
      store.dispatch(roomActions.setPermissionsFromRoles(permissionsFromRoles));
      store.dispatch(roomActions.setPassword(password));

      const myRoles = store.getState().me.roles;

      for (const role of roles) {
        if (!myRoles.includes(role)) {
          store.dispatch(meActions.addRole(role));

          if (role !== "webinar_presenter")
            store.dispatch(
              requestActions.notify({
                text: intl.formatMessage(
                  {
                    id: "roles.gotRole",
                    defaultMessage: `You got the role: ${role}`,
                  },
                  { role }
                ),
              })
            );
        }
      }

      for (const peer of peers) {
        store.dispatch(
          peerActions.addPeer({ ...peer, consumers: [], dataConsumers: [] })
        );
      }

      this._spotlights.addPeers(peers);

      this._spotlights.on("spotlights-updated", (spotlights) => {
        store.dispatch(roomActions.setSpotlights(spotlights));
        this.updateSpotlights(spotlights);
      });

      await this._updateAudioOutputDevices();

      const { selectedAudioOutputDevice } = store.getState().settings;

      if (this._audioOutputDevices !== {}) {
        let defaultDevice = this._audioOutputDevices.default
          ? this._audioOutputDevices.default.deviceId
          : Object.keys(this._audioOutputDevices)[0];
        store.dispatch(
          settingsActions.setSelectedAudioOutputDevice(defaultDevice)
        );
      }
      if (store.getState().me.browser.flag === "safari") {
        await this.enableMic();
        await this.muteMic();
      }
      store.dispatch(roomActions.setRoomState("connected"));

      // Clean all the existing notifcations.
      store.dispatch(notificationActions.removeAllNotifications());

      await this.getServerHistory(joinAudio, joinVideo);

      store.dispatch(
        requestActions.notify({
          text: intl.formatMessage({
            id: "room.joined",
            defaultMessage: "You have joined the room",
          }),
        })
      );

      this._spotlights.start();
    } catch (error) {
      logger.error('_joinRoom() failed:"%o"', error);

      store.dispatch(
        requestActions.notify({
          type: "error",
          text: intl.formatMessage({
            id: "room.cantJoin",
            defaultMessage: "Unable to join the room",
          }),
        })
      );

      this.close();
    }
  }

  async lockRoom() {
    logger.debug("lockRoom()");

    if (store.getState().room.hasPassword) {
      store.dispatch(
        requestActions.notify({
          text: intl.formatMessage({
            id: "room.lockedPassword",
            defaultMessage: "Room has already locked with password",
          }),
        })
      );
      return;
    }

    try {
      await this.sendRequest("uiAction:lockRoom");

      store.dispatch(roomActions.setRoomLocked());

      store.dispatch(
        requestActions.notify({
          text: intl.formatMessage({
            id: "room.youLocked",
            defaultMessage: "You locked the room",
          }),
        })
      );
    } catch (error) {
      store.dispatch(
        requestActions.notify({
          type: "error",
          text: intl.formatMessage({
            id: "room.cantLock",
            defaultMessage: "Unable to lock the room",
          }),
        })
      );

      logger.error("lockRoom() | failed: %o", error);
    }
  }

  async unlockRoom() {
    logger.debug("unlockRoom()");

    try {
      await this.sendRequest("uiAction:unlockRoom");

      store.dispatch(roomActions.setRoomUnLocked());

      store.dispatch(
        requestActions.notify({
          text: intl.formatMessage({
            id: "room.youUnLocked",
            defaultMessage: "You unlocked the room",
          }),
        })
      );
    } catch (error) {
      store.dispatch(
        requestActions.notify({
          type: "error",
          text: intl.formatMessage({
            id: "room.cantUnLock",
            defaultMessage: "Unable to unlock the room",
          }),
        })
      );

      logger.error("unlockRoom() | failed: %o", error);
    }
  }

  async setAccessCode(code) {
    logger.debug("setAccessCode()");

    try {
      await this.sendRequest("uiAction:setAccessCode", { accessCode: code });

      store.dispatch(roomActions.setAccessCode(code));

      store.dispatch(
        requestActions.notify({
          text: "Access code saved.",
        })
      );
    } catch (error) {
      logger.error("setAccessCode() | failed: %o", error);
      store.dispatch(
        requestActions.notify({
          type: "error",
          text: "Unable to set access code.",
        })
      );
    }
  }

  async setJoinByAccessCode(value) {
    logger.debug("setJoinByAccessCode()");

    try {
      await this.sendRequest("uiAction:setJoinByAccessCode", {
        joinByAccessCode: value,
      });

      store.dispatch(roomActions.setJoinByAccessCode(value));

      store.dispatch(
        requestActions.notify({
          text: `You switched Join by access-code to ${value}`,
        })
      );
    } catch (error) {
      logger.error("setAccessCode() | failed: %o", error);
      store.dispatch(
        requestActions.notify({
          type: "error",
          text: "Unable to set join by access code.",
        })
      );
    }
  }

  async setWhiteBoardEnabled() {
    try {
      await this.sendRequest("moderator:enableWhiteboard", {
        enableWhiteboard: !store.getState().room.whiteBoardEnabled,
      });
      store.dispatch(roomActions.setWhiteBoardEnabled());
    } catch (error) {
      logger.error("setMirrorOwnImage() failed: %o", error);
    }
  }

  async enableMic() {
    if (this._micProducer) return;

    if (this._mediasoupDevice && !this._mediasoupDevice.canProduce("audio")) {
      logger.error("enableMic() | cannot produce audio");

      return;
    }

    let track;

    store.dispatch(meActions.setAudioInProgress(true));

    try {
      const deviceId = await this._getAudioDeviceId();

      const device = this._audioDevices[deviceId];

      if (!device) throw new Error("no audio devices");

      logger.debug(
        "enableMic() | new selected audio device [device:%o]",
        device
      );

      logger.debug("enableMic() | calling getUserMedia()");

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: { ideal: deviceId },
          echoCancellation: true,
          googTypingNoiseDetection: true,
          googEchoCancellation: true,
          googAutoGainControl: true,
          googNoiseSuppression: true,
          googHighpassFilter: true,
          googAudioMirroring: false,
          googNoiseSuppression2: true,
          googEchoCancellation2: true,
          googAutoGainControl2: true,
          googDucking: true,
          chromeRenderToAssociatedSink: true,
          autoGainControl: true,
          channelCount: 1,
          noiseSuppression: true,
          noiseSuppression2: true,
          echoCancellation2: true,
          latency: 1.0, // latency in seconds
          latency2: 1.0,
          googLatency: 1.0,
          googLatency2: 1.0,
          sampleRate: 96000,
          sampleSize: 16,
        },
      });

      this.appendRecording(stream);
      track = stream.getAudioTracks()[0];

      this._micProducer = await this._sendTransport.produce({
        track,
        codecOptions: {
          opusStereo: false,
          opusDtx: true,
          opusFec: true,
          opusMaxPlaybackRate: 48000,
        },
        appData: { source: "mic" },
      });

      store.dispatch(
        producerActions.addProducer({
          id: this._micProducer.id,
          source: "mic",
          paused: this._micProducer.paused,
          track: this._micProducer.track,
          rtpParameters: this._micProducer.rtpParameters,
          codec: this._micProducer.rtpParameters.codecs[0].mimeType.split(
            "/"
          )[1],
        })
      );

      store.dispatch(settingsActions.setSelectedAudioDevice(deviceId));

      await this._updateAudioDevices();

      this._micProducer.on("transportclose", () => {
        this._micProducer = null;
      });

      this._micProducer.on("trackended", () => {
        store.dispatch(
          requestActions.notify({
            type: "error",
            text: intl.formatMessage({
              id: "devices.microphoneDisconnected",
              defaultMessage: "Microphone disconnected",
            }),
          })
        );

        this.disableMic().catch(() => {});
      });

      this._micProducer.volume = 0;

      const harkStream = new MediaStream();

      harkStream.addTrack(track);

      if (!harkStream.getAudioTracks()[0])
        throw new Error("enableMic(): given stream has no audio track");

      if (this._hark != null) this._hark.stop();

      this._hark = hark(harkStream, { play: false });

      this.volumes = [];

      // eslint-disable-next-line no-unused-vars
      this._hark.on("volume_change", (dBs, threshold) => {
        // The exact formula to convert from dBs (-100..0) to linear (0..1) is:
        //   Math.pow(10, dBs / 20)
        // However it does not produce a visually useful output, so let exagerate
        // it a bit. Also, let convert it from 0..1 to 0..10 and avoid value 1 to
        // minimize component renderings.
        let volume = Math.round(Math.pow(10, dBs / 85) * 10);

        if (volume === 1) volume = 0;

        volume = Math.round(volume);

        if (this._micProducer && volume !== this._micProducer.volume) {
          this._micProducer.volume = volume;

          store.dispatch(peerVolumeActions.setPeerVolume(this._peerId, volume));
        }
      });
    } catch (error) {
      logger.error("enableMic() failed:%o", error);

      store.dispatch(
        requestActions.notify({
          type: "error",
          text: intl.formatMessage({
            id: "devices.microphoneError",
            defaultMessage: "An error occured while accessing your microphone",
          }),
        })
      );

      if (track) track.stop();
    }

    store.dispatch(meActions.setAudioInProgress(false));
  }

  async disableMic() {
    logger.debug("disableMic()");

    if (!this._micProducer) return;

    store.dispatch(meActions.setAudioInProgress(true));

    this._micProducer.close();

    store.dispatch(producerActions.removeProducer(this._micProducer.id));

    try {
      await this.sendRequest("peer:closeProducer", {
        producerId: this._micProducer.id,
      });
    } catch (error) {
      logger.error('disableMic() [error:"%o"]', error);
    }

    this._micProducer = null;

    store.dispatch(meActions.setAudioInProgress(false));
  }

  async enableScreenAudioSharing(stream) {
    if (!stream || !stream.getAudioTracks()[0]) return;

    let track;
    try {
      if (this._screenSharingAudioProducer) return;

      track = stream.getAudioTracks()[0];

      this._screenSharingAudioProducer = await this._sendTransport.produce({
        track,
        codecOptions: {
          opusStereo: false,
          opusDtx: true,
          opusFec: true,
          opusMaxPlaybackRate: 48000,
        },
        appData: { source: "mic" },
      });

      store.dispatch(
        producerActions.addProducer({
          id: this._screenSharingAudioProducer.id,
          deviceLabel: "mic",
          paused: this._screenSharingAudioProducer.paused,
          track: this._screenSharingAudioProducer.track,
          rtpParameters: this._screenSharingAudioProducer.rtpParameters,
          codec: this._screenSharingAudioProducer.rtpParameters.codecs[0].mimeType.split(
            "/"
          )[1],
        })
      );

      this._screenSharingAudioProducer.on("transportclose", () => {
        this._screenSharingAudioProducer = null;
      });

      this._screenSharingAudioProducer.on("trackended", () => {
        store.dispatch(
          requestActions.notify({
            type: "error",
            text: intl.formatMessage({
              id: "devices.screenSharingDisconnected",
              defaultMessage: "Screen audio sharing disconnected ",
            }),
          })
        );

        this.disableScreenAudioSharing().catch(() => {});
      });
    } catch (error) {
      logger.error("enableScreenAudioSharing() failed: %o", error);
      store.dispatch(
        requestActions.notify({
          type: "error",
          text: intl.formatMessage({
            id: "devices.screenSharingError",
            defaultMessage:
              "An error occured while accessing your screen audio",
          }),
        })
      );

      if (track) track.stop();
    }
  }

  async enableScreenSharing() {
    if (
      [...this._consumers.values()].filter(
        (c) => c._appData.source === "screen"
      ).length >= 1
    ) {
      store.dispatch(
        requestActions.notify({
          type: "error",
          text: intl.formatMessage({
            id: "devices.multiScreenSharingError",
            defaultMessage: "2 people can not share a screen at the same time",
          }),
        })
      );
      return;
    }

    if (this._screenSharingProducer) return;

    if (!this._mediasoupDevice.canProduce("video")) {
      logger.error("enableScreenSharing() | cannot produce video");

      return;
    }

    let track;

    store.dispatch(meActions.setScreenShareInProgress(true));

    try {
      const available = this._screenSharing.isScreenShareAvailable();

      if (!available) throw new Error("screen sharing not available");

      logger.debug("enableScreenSharing() | calling getUserMedia()");

      let screenShareConfig = {
        width: 1920,
        height: 1080,
        frameRate: 4,
        audio: shareScreenAudio === true,
      };

      let encodings = [
        {
          maxFramerate: 4,
          adaptivePtime: true,
          maxBitrate: 650000,
        },
      ];

      if (store.getState().settings.optimizeScreenShareForVideo === true) {
        screenShareConfig = {
          width: 1280,
          height: 720,
          frameRate: 18,
          audio: true,
        };
        encodings = [
          {
            maxFramerate: 18,
            adaptivePtime: true,
            maxBitrate: 875000,
          },
        ];
      }

      const stream = await this._screenSharing.start(screenShareConfig);

      track = stream.getVideoTracks()[0];

      this._screenSharingProducer = await this._sendTransport.produce({
        encodings,
        track,
        appData: {
          source: "screen",
        },
      });

      store.dispatch(
        producerActions.addProducer({
          id: this._screenSharingProducer.id,
          deviceLabel: "screen",
          source: "screen",
          paused: this._screenSharingProducer.paused,
          track: this._screenSharingProducer.track,
          rtpParameters: this._screenSharingProducer.rtpParameters,
          codec: this._screenSharingProducer.rtpParameters.codecs[0].mimeType.split(
            "/"
          )[1],
        })
      );

      this._screenSharingProducer.on("transportclose", () => {
        this._screenSharingProducer = null;
      });

      this._screenSharingProducer.on("trackended", () => {
        store.dispatch(
          requestActions.notify({
            type: "error",
            text: intl.formatMessage({
              id: "devices.screenSharingDisconnected",
              defaultMessage: "Screen sharing disconnected",
            }),
          })
        );

        this.disableScreenSharing().catch(() => {});
      });

      if (
        stream.getAudioTracks()[0] &&
        shareScreenAudio &&
        shareScreenAudio === true
      )
        this.enableScreenAudioSharing(stream);

      logger.debug("enableScreenSharing() succeeded");
    } catch (error) {
      logger.error("enableScreenSharing() failed: %o", error);

      store.dispatch(
        requestActions.notify({
          type: "error",
          text: intl.formatMessage({
            id: "devices.screenSharingError",
            defaultMessage: "An error occured while accessing your screen",
          }),
        })
      );

      if (track) track.stop();
    }

    store.dispatch(meActions.setScreenShareInProgress(false));
  }

  async disableScreenAudioSharing() {
    logger.debug("disableScreenAudioSharing()");

    if (!this._screenSharingAudioProducer) return;

    this._screenSharingAudioProducer.close();

    store.dispatch(
      producerActions.removeProducer(this._screenSharingAudioProducer.id)
    );

    try {
      await this.sendRequest("peer:closeProducer", {
        producerId: this._screenSharingAudioProducer.id,
      });
    } catch (error) {
      logger.error('disableScreenAudioSharing() [error:"%o"]', error);
    }

    this._screenSharingAudioProducer = null;
  }

  async disableScreenSharing() {
    logger.debug("disableScreenSharing()");

    if (!this._screenSharingProducer) return;

    store.dispatch(meActions.setScreenShareInProgress(true));

    this._screenSharingProducer.close();

    store.dispatch(
      producerActions.removeProducer(this._screenSharingProducer.id)
    );

    try {
      await this.sendRequest("peer:closeProducer", {
        producerId: this._screenSharingProducer.id,
      });
    } catch (error) {
      logger.error('disableScreenSharing() [error:"%o"]', error);
    }

    this._screenSharingProducer = null;

    this.disableScreenAudioSharing();

    store.dispatch(meActions.setScreenShareInProgress(false));
  }

  async suspendMeeting(meetingSuspended) {
    logger.debug("suspendMeeting()");

    await store.dispatch(roomActions.setMeetingSuspended(meetingSuspended));

    await this.disableMic();
    await this.disableScreenAudioSharing();
    await this.disableScreenSharing();
    await this.disableWebcam();

    if (store.getState().room.roomRecordingState === "recording") {
      await this.pauseRecording();
    }
  }

  async unsuspendMeeting(meetingSuspended) {
    logger.debug("unsuspendMeeting()");

    await store.dispatch(roomActions.setMeetingSuspended(meetingSuspended));

    if (store.getState().room.roomRecordingState === "pause") {
      await this.resumeRecording();
    }
  }

  async enableWebcam() {
    if (this._webcamProducer) return;

    if (!this._mediasoupDevice.canProduce("video")) {
      logger.error("enableWebcam() | cannot produce video");

      return;
    }

    if (
      Object.values(store.getState().consumers).filter(
        (c) => c.kind === "video"
      ).length >= store.getState().room.maxNumberOfVideo.current
    ) {
      store.dispatch(
        requestActions.notify({
          type: "error",
          text: intl.formatMessage({
            id: "room.reachedMaxNumberOfVideo",
            defaultMessage:
              "Maximum number of video reached so you can't open the camera.",
          }),
        })
      );
      return;
    }

    let track;

    store.dispatch(meActions.setWebcamInProgress(true));

    try {
      const deviceId = await this._getWebcamDeviceId();

      const device = this._webcams[deviceId];

      if (!device) throw new Error("no webcam devices");

      logger.debug(
        "_setWebcamProducer() | new selected webcam [device:%o]",
        device
      );

      logger.debug("_setWebcamProducer() | calling getUserMedia()");

      let resolution;

      if ([...this._consumers.values()].filter((c) => c.kind === "video") === 0)
        resolution = "medium";
      else resolution = this.getOptimizedVideoResolution();

      VIDEO_CONSTRAINS[
        resolution
      ].aspectRatio = store.getState().settings.webcamAspectRatio;

      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: { ideal: deviceId },
          ...VIDEO_CONSTRAINS[resolution],
        },
      });
      store.dispatch(settingsActions.setVideoResolution(resolution));

      track = stream.getVideoTracks()[0];
      let webcamCodecName = "h264";
      if (
        this._mediasoupDevice.rtpCapabilities.codecs.findIndex(
          (codec) => codec.mimeType.toLowerCase() === "video/" + webcamCodecName
        ) === -1
      )
        webcamCodecName = this._mediasoupDevice.rtpCapabilities.codecs.find(
          (c) => c.kind === "video"
        );

      if (this._useSimulcast) {
        let encodings;
        if (webcamCodecName === "vp9") encodings = VIDEO_KSVC_ENCODINGS;
        else {
          if ("simulcastEncodings" in window.config)
            encodings = window.config.simulcastEncodings;
          else encodings = VIDEO_SIMULCAST_ENCODINGS;
        }

        this._webcamProducer = await this._sendTransport.produce({
          track,
          codec: this._mediasoupDevice.rtpCapabilities.codecs.find(
            (codec) =>
              codec.mimeType.toLowerCase() === "video/" + webcamCodecName
          ),
          //encodings,
          encodings: [
            {
              maxBitrate: 650000,
              maxFramerate: 10,
              adaptivePtime: true,
            },
          ],
          codecOptions: {
            videoGoogleStartBitrate: 1000,
          },
          appData: {
            source: "webcam",
          },
        });
      } else {
        this._webcamProducer = await this._sendTransport.produce({
          track,
          codec: this._mediasoupDevice.rtpCapabilities.codecs.find(
            (codec) =>
              codec.mimeType.toLowerCase() === "video/" + webcamCodecName
          ),
          encodings: [
            {
              maxBitrate: 650000,
              maxFramerate: 10,
              adaptivePtime: true,
            },
          ],
          appData: {
            source: "webcam",
          },
        });
      }

      store.dispatch(
        producerActions.addProducer({
          id: this._webcamProducer.id,
          deviceLabel: device.label,
          source: "webcam",
          paused: this._webcamProducer.paused,
          track: this._webcamProducer.track,
          rtpParameters: this._webcamProducer.rtpParameters,
          codec: this._webcamProducer.rtpParameters.codecs[0].mimeType.split(
            "/"
          )[1],
        })
      );

      store.dispatch(settingsActions.setSelectedWebcamDevice(deviceId));

      await this._updateWebcams();

      this._webcamProducer.on("transportclose", () => {
        this._webcamProducer = null;
      });

      this._webcamProducer.on("trackended", () => {
        store.dispatch(
          requestActions.notify({
            type: "error",
            text: intl.formatMessage({
              id: "devices.cameraDisconnected",
              defaultMessage: "Camera disconnected",
            }),
          })
        );

        this.disableWebcam().catch(() => {});
      });
      store.dispatch(
        requestActions.notify({
          text: intl.formatMessage({
            id: "webcam.opened",
            defaultMessage: "Your camera has turned on",
          }),
        })
      );
      logger.debug("_setWebcamProducer() succeeded");
    } catch (error) {
      logger.error("_setWebcamProducer() failed:%o", error);

      store.dispatch(
        requestActions.notify({
          type: "error",
          text: intl.formatMessage({
            id: "devices.cameraError",
            defaultMessage: "An error occured while accessing your camera",
          }),
        })
      );

      if (track) track.stop();
    }

    store.dispatch(meActions.setWebcamInProgress(false));
    if (
      !this.isWebcamEnabledOnceFlag &&
      this._webcamProducer &&
      this._webcamProducer.track
    ) {
      this.isWebcamEnabledOnceFlag = true;
      setInterval(() => {
        if (this._webcamProducer && this._webcamProducer.track) {
          if (
            store.getState().settings.resolution !==
            this.getOptimizedVideoResolution()
          )
            this.changeVideoResolution();
        }
      }, 10000);
    }
  }

  async disableWebcam() {
    logger.debug("disableWebcam()");

    if (!this._webcamProducer) return;

    store.dispatch(meActions.setWebcamInProgress(true));

    this._webcamProducer.close();

    store.dispatch(producerActions.removeProducer(this._webcamProducer.id));

    try {
      await this.sendRequest("peer:closeProducer", {
        producerId: this._webcamProducer.id,
      });
    } catch (error) {
      logger.error('disableWebcam() [error:"%o"]', error);
    }
    store.dispatch(
      requestActions.notify({
        text: intl.formatMessage({
          id: "webcam.closed",
          defaultMessage: "Your camera has turned off",
        }),
      })
    );

    this._webcamProducer = null;

    store.dispatch(meActions.setWebcamInProgress(false));
  }

  async _updateAudioDevices() {
    logger.debug("_updateAudioDevices()");

    // Reset the list.
    this._audioDevices = {};

    try {
      logger.debug("_updateAudioDevices() | calling enumerateDevices()");

      const devices = await navigator.mediaDevices.enumerateDevices();

      for (const device of devices) {
        if (device.kind !== "audioinput") continue;

        this._audioDevices[device.deviceId] = device;
      }

      store.dispatch(meActions.setAudioDevices(this._audioDevices));
    } catch (error) {
      logger.error("_updateAudioDevices() failed:%o", error);
    }
  }

  async _updateWebcams() {
    logger.debug("_updateWebcams()");

    // Reset the list.
    this._webcams = {};

    try {
      logger.debug("_updateWebcams() | calling enumerateDevices()");

      const devices = await navigator.mediaDevices.enumerateDevices();

      for (const device of devices) {
        if (device.kind !== "videoinput") continue;

        this._webcams[device.deviceId] = device;
      }

      store.dispatch(meActions.setWebcamDevices(this._webcams));
    } catch (error) {
      logger.error("_updateWebcams() failed:%o", error);
    }
  }

  async _getAudioDeviceId() {
    logger.debug("_getAudioDeviceId()");

    try {
      logger.debug("_getAudioDeviceId() | calling _updateAudioDeviceId()");

      await this._updateAudioDevices();

      const { selectedAudioDevice } = store.getState().settings;

      if (selectedAudioDevice && this._audioDevices[selectedAudioDevice])
        return selectedAudioDevice;
      else {
        const audioDevices = Object.values(this._audioDevices);

        return audioDevices[0] ? audioDevices[0].deviceId : null;
      }
    } catch (error) {
      logger.error("_getAudioDeviceId() failed:%o", error);
    }
  }

  async _getWebcamDeviceId() {
    logger.debug("_getWebcamDeviceId()");

    try {
      logger.debug("_getWebcamDeviceId() | calling _updateWebcams()");

      await this._updateWebcams();

      const { selectedWebcam } = store.getState().settings;

      if (selectedWebcam && this._webcams[selectedWebcam])
        return selectedWebcam;
      else {
        const webcams = Object.values(this._webcams);

        return webcams[0] ? webcams[0].deviceId : null;
      }
    } catch (error) {
      logger.error("_getWebcamDeviceId() failed:%o", error);
    }
  }

  async _updateAudioOutputDevices() {
    logger.debug("_updateAudioOutputDevices()");

    // Reset the list.
    this._audioOutputDevices = {};

    try {
      logger.debug("_updateAudioOutputDevices() | calling enumerateDevices()");

      const devices = await navigator.mediaDevices.enumerateDevices();

      for (const device of devices) {
        if (device.kind !== "audiooutput") continue;

        this._audioOutputDevices[device.deviceId] = device;
      }

      store.dispatch(meActions.setAudioOutputDevices(this._audioOutputDevices));
    } catch (error) {
      logger.error("_updateAudioOutputDevices() failed:%o", error);
    }
  }

  dispatchNotification(id, type, defaultMessage) {
    store.dispatch(
      requestActions.notify({
        type: type,
        text: intl.formatMessage({
          id: id,
          defaultMessage: defaultMessage,
        }),
      })
    );
  }

  getDeviceFlag() {
    return this._device.flag;
  }

  getWebcams() {
    return this._webcams;
  }
  getAudioDevices() {
    return this._audioDevices;
  }
  resetVolumeLevel() {
    store.dispatch(peerVolumeActions.setPeerVolume(this._peerId, 0));
  }
  getPeerId() {
    return this._peerId;
  }

  async enableScreenSharingForRecording() {
    let track;

    try {
      const available = this._screenSharingForRecorder.isScreenShareAvailable();

      if (!available) throw new Error("screen sharing not available");

      logger.debug("enableScreenSharing() | calling getUserMedia()");

      const stream = await this._screenSharingForRecorder.start({
        width: 960,
        height: 540,
        frameRate: 18,
      });

      this.appendRecording(stream, true);

      track = stream.getVideoTracks()[0];

      track.addEventListener("ended", () => {
        store.dispatch(roomActions.setRecordingNotificationStatus(false));
        this.sendRequest("moderator:setRecordingNotificationStatus", {
          recordingNotificationStatus: false,
          peerId: this._peerId,
        });
        this.stopRecording();
      });

      store.dispatch(roomActions.setRecordingNotificationStatus(true));
      await this.sendRequest("moderator:setRecordingNotificationStatus", {
        recordingNotificationStatus: true,
        peerId: this._peerId,
      });

      logger.debug("enableScreenSharing() succeeded");
    } catch (error) {
      logger.error("enableScreenSharing() failed: %o", error);

      if (track) track.stop();
    }
  }

  async startRecording() {
    if (!this._micProducer) {
      await this.unmuteMic();
      await this.muteMic();
    }
    await this.enableScreenSharingForRecording();
  }

  appendRecording(stream, start = false) {
    this.recordStreams.push(stream);
    this.recordStreams = this.recordStreams.filter((str) => str.active);
    if (!start && !this._recording) {
      return;
    }
    if (!this._recorder) {
      this._recorder = RecordRTC(this.recordStreams, {
        type: "video",
        video: {
          width: 960,
          height: 540,
        },
        audioBitsPerSecond: 128000,
        videoBitsPerSecond: 2560000,
        frameRate: 18,
        /*        canvas: {
                  width: 1280,
                  height: 720
                },*/
        mimeType: "video/webm",
        timeSlice: 1000,
        /*        timeSlice: 1000,
                ondataavailable: (blob)=>{
                  blobs.push(blob);
                  console.log('A ')
                }*/
      });
      this._recorder.startRecording();
      this._recording = true;
      store.dispatch(roomActions.setRoomRecordingState("recording"));
    } else {
      this._recorder.getInternalRecorder().getMixer().appendStreams([stream]);
    }
  }

  pauseRecording() {
    if (!this._recorder) return;
    store.dispatch(roomActions.setRoomRecordingState("pause"));
    this._recorder.getInternalRecorder().pause();
    store.dispatch(roomActions.setRecordingNotificationStatus(false));
    this.sendRequest("moderator:setRecordingNotificationStatus", {
      recordingNotificationStatus: false,
      peerId: this._peerId,
    });
  }

  resumeRecording() {
    if (!this._recorder) return;
    store.dispatch(roomActions.setRoomRecordingState("recording"));
    this._recorder.getInternalRecorder().resume();
    store.dispatch(roomActions.setRecordingNotificationStatus(true));
    this.sendRequest("moderator:setRecordingNotificationStatus", {
      recordingNotificationStatus: true,
      peerId: this._peerId,
    });
  }

  stopRecording() {
    store.dispatch(roomActions.setRoomRecordingState("stop"));
    this._screenSharingForRecorder.stop();
    this._recording = false;
    if (!this._recorder) return;
    let blobs = this.blobs;
    this._recorder.stopRecording(() => {
      blobs.push(this._recorder.getBlob());
      //console.log(blobs.length);
      const webm = blobs.reduce(
        (a, b) => new Blob([a, b], { type: "video/webm" })
      );
      saveAs(
        webm,
        this._roomId +
          " " +
          moment(new Date()).format("YYYY-MM-DD HH-MM-SS") +
          ".webm"
      );
      this._recorder.reset();
      this._recorder = null;
      this.blobs = null;
      this.blobs = [];
    });
    store.dispatch(roomActions.setRecordingNotificationStatus(false));
    this.sendRequest("moderator:setRecordingNotificationStatus", {
      recordingNotificationStatus: false,
      peerId: this._peerId,
    });
  }

  async enableDataProducer() {
    logger.debug("enableDataProducer()");

    if (this._dataProducer) return;

    try {
      // Create DataProducer.
      this._dataProducer = await this._sendTransport.produceData({
        ordered: false,
        maxRetransmits: 1,
        label: "chat",
        priority: "medium",
        appData: { info: "myDataProducer" },
      });
      store.dispatch(
        dataProducerActions.addDataProducer({
          id: this._dataProducer.id,
          sctpStreamParameters: this._dataProducer.sctpStreamParameters,
          label: this._dataProducer.label,
          protocol: this._dataProducer.protocol,
        })
      );

      this._dataProducer.on("transportclose", () => {
        this._dataProducer = null;
      });

      this._dataProducer.on("open", () => {
        logger.debug('DataProducer "open" event');
      });

      this._dataProducer.on("close", () => {
        logger.error('DataProducer "close" event');
        this._dataProducer = null;
      });

      this._dataProducer.on("error", (error) => {
        logger.error('DataProducer "error" event:%o', error);
      });

      this._dataProducer.on("bufferedamountlow", () => {
        logger.debug('DataProducer "bufferedamountlow" event');
      });
    } catch (error) {
      logger.error("enableDataProducer() | failed:%o", error);

      throw error;
    }
  }

  async sendChatMessage(chatMessage) {
    logger.debug('sendChatMessage() [text:"%s]', chatMessage.text);

    store.dispatch(
      chatActions.addUserMessage(
        chatMessage.text,
        store.getState().settings.displayName,
        chatMessage.recipient,
        chatMessage.recipientDisplayName,
        chatMessage.senderId,
        chatMessage.fileId,
        chatMessage.fileName
      )
    );

    if (
      !this._dataProducer ||
      this._connectionState !== "connected" ||
      !this._useDataChannel
    ) {
      logger.warn(
        "WebRTC Transport state %o, sending chat message over websocket",
        this._connectionState
      );
      await this.sendChatOverWebSocket(chatMessage);
    } else {
      let plain_text = chatMessage.text;
      try {
        if (chatMessage.recipient !== "everyone") {
          chatMessage.text = this._encrypt_message(chatMessage);
        }
        this._dataProducer.send(JSON.stringify(chatMessage));
      } catch (error) {
        logger.error("sendChatMessage() | failed: %o", error);
        logger.warn("sendChatMessage() | send over alternative channel");
        chatMessage.text = plain_text;
        await this.sendChatOverWebSocket(chatMessage);
      }
    }
    if (!this._dataProducer) {
      logger.error("No DataProducer");
    }
  }

  onChatMessage(messages) {
    store.dispatch(chatActions.addBatchResponseMessage(messages));

    if (
      !store.getState().toolarea.toolAreaOpen ||
      (store.getState().toolarea.toolAreaOpen &&
        store.getState().toolarea.currentToolTab !== "chat")
    ) {
      // Make sound
      store.dispatch(roomActions.setToolbarsVisible(true));

      if (store.getState().settings.chatSound) {
        this._soundNotification();
      }
    }
  }

  async sendChatOverWebSocket(chatMessage) {
    try {
      await this.sendRequest("uiAction:chatMessage", { chatMessage });
    } catch (error) {
      logger.error(
        "sendChatMessage() over alternative channel | failed: %o",
        error
      );

      store.dispatch(
        requestActions.notify({
          type: "error",
          text: intl.formatMessage({
            id: "room.chatError",
            defaultMessage: "Unable to send chat message",
          }),
        })
      );
    }
  }

  _encrypt_message(chatMessage) {
    const passphrase = this._secretKeysSent.get(chatMessage.recipient);
    return CryptoJS.AES.encrypt(
      JSON.stringify(chatMessage.text),
      passphrase
    ).toString();
  }

  _decrypt_message(sendingPeer, message_string) {
    const passphrase = this._secretKeysReceived.get(sendingPeer.id);
    const bytes = CryptoJS.AES.decrypt(message_string, passphrase);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  _generateKey() {
    logger.debug("_generateKey()");
    const uuid = require("uuid");
    return uuid.v4();
  }

  async toggleScreenPin(peerId, flag) {
    logger.debug('toggleScreenPin() [peerId:"%s] [flag:"%s]', peerId, flag);
    try {
      const { overLimit } = await this.sendRequest("moderator:screenPin", {
        peerId: peerId,
        flag: flag,
      });

      if (overLimit) {
        store.dispatch(
          requestActions.notify({
            type: "error",
            text: intl.formatMessage({
              id: "label.pin.overLimit",
              defaultMessage: "Pinned participant size is over limit",
            }),
          })
        );
      }
    } catch (error) {
      logger.error("toggleScreenPin() | failed: %o", error);

      if (flag) {
        store.dispatch(
          requestActions.notify({
            type: "error",
            text: intl.formatMessage({
              id: "label.pinError",
              defaultMessage: "Pinning is not success",
            }),
          })
        );
      } else {
        store.dispatch(
          requestActions.notify({
            type: "error",
            text: intl.formatMessage({
              id: "label.unpinError",
              defaultMessage: "Unpinning is not success",
            }),
          })
        );
      }
    }
  }

  async retrieveParticipantLog(callback) {
    try {
      const { participantLog } = await this.sendRequest(
        "moderator:retrieveParticipantLog"
      );

      store.dispatch(roomActions.setParticipantLog(participantLog));
      callback();
    } catch (error) {
      logger.error("retrieveParticipantLog() | failed: %o", error);
    }
  }
}
